import React, {useState} from 'react';
import Logo from '../images/logo.png';
// import {Container,Button, Link } from 'react-floating-action-button';
import {Container, Row, Col,Button } from 'reactstrap';
import { Link } from "react-scroll";
import {NavLink} from "react-router-dom";

import { homePageUrl } from '../module/Constants.js';

const Header = () => {
  
  const [isMenuOpen, setMenuOpen] = useState(false);
 
  return(
    <div>
    <div className="bs-header typ-fix-header">
    
    <Container className="hedr">
      <div className="desktop">
      
      <div className={isMenuOpen ?"bs-header bg " : "bs-header non-bg "}>
     
          <nav className="navigation">
          <Row>
          <Col lg={2} md={2} className="logo">

            <div className ="nav-left">
              <div className="nav-logo">
                {/* <NavLink exact to="/clients/tbs-website/"><img src={Logo} alt="Logo"/></NavLink> */}
                <NavLink exact to={`${homePageUrl}`}><img src={Logo} alt="Logo"/></NavLink>
              </div>
            </div>
            </Col>
            <Col lg={10} md={10}>
            <div className="nav-right">
              <ul className={isMenuOpen ? "nav-links menuDisplayed":"nav-links"}  id="sidemenu-bar">
                
              {/* <li>
                  <div className="two"><NavLink exact to="/clients/tbs-website/about">About&nbsp;Us</NavLink></div>
                  </li>
                <li>
                  <div className="one"><NavLink exact to="/clients/tbs-website/products">Products</NavLink></div></li>
                
                <li>
                  <div className="three"><NavLink exact to="/clients/tbs-website/brands">Brands</NavLink></div></li>
                <li>
                  <div className="four"><NavLink exact to="/clients/tbs-website/projects">Projects</NavLink></div></li>
                <li>
                  <div className="five"><NavLink exact to="/clients/tbs-website/contact">Contact</NavLink></div></li>
                <li className="btn">
                    <NavLink exact to="/clients/tbs-website/experience">experience</NavLink>
                </li> */}
                
                <li>
                <div className="two"><NavLink exact to={`${homePageUrl}about`}>About&nbsp;Us</NavLink></div>
                </li>
                <li>
                  <div className="one"><NavLink exact to={`${homePageUrl}products`}>Products</NavLink></div>
                </li>
                <li>
                  <div className="three"><NavLink exact to={`${homePageUrl}brands`}>Brands</NavLink></div></li>
                <li>
                  <div className="four"><NavLink exact to={`${homePageUrl}projects`}>Projects</NavLink></div></li>
                <li>
                  <div className="five"><NavLink exact to ={`${homePageUrl}contact`}>Contact</NavLink></div></li>
                <li className="btn">
                    <NavLink exact to={`${homePageUrl}experience`}>experience</NavLink>
                </li>

              </ul>
            </div>
            </Col>
            
        </Row>
          </nav>
         
        </div>
        
      </div>
      <div className="mobile">
        <div className={isMenuOpen ?"bs-header bg lyt-section" : "bs-header non-bg lyt-section"}>
          <nav className="navigation">
            <div className ="nav-left">
              <div className="nav-logo">
                {/* <NavLink exact to="/clients/tbs-website/"><img src={Logo} /></NavLink> */}
                <NavLink exact to={`${homePageUrl}`}><img src={Logo} /></NavLink>
              </div>
              <span className="menu">
                <a onClick={() => setMenuOpen(!isMenuOpen)}
                className={isMenuOpen ? "menuCross" : ""} 
                id="menu_toggle" >
                  <hr id="icon-bar-1" style={{color:'#000'}}/>
                  <hr id="icon-bar-2" style={{color:'#000'}}/>
                </a>
              </span>
            </div>
            <div className="nav-right">
              <ul className={isMenuOpen ? "nav-links menuDisplayed":"nav-links"}  id="sidemenu-bar">

                
                
                {/* <li onClick={() => setMenuOpen(false)}>
                  <div className="two"><NavLink exact to="/clients/tbs-website/about">About</NavLink> </div>
                </li>
                
                <li onClick={() => setMenuOpen(false)}>
                  <div className="one"><NavLink exact to="/clients/tbs-website/products">Products</NavLink></div>
                </li>
                
                <li onClick={() => setMenuOpen(false)}>
                  <div className="three"><NavLink exact to="/clients/tbs-website/brands">Brands</NavLink></div>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                  <div className="four"><NavLink exact to="/clients/tbs-website/projects">Projects</NavLink></div>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                  <div className="five"><NavLink exact to="/clients/tbs-website/contact">Contact</NavLink></div>
                </li>
                <li onClick={() => setMenuOpen(false)}>
                  <div className="six"><NavLink exact to="/clients/tbs-website/experience">experience</NavLink></div>                    
                </li> */}

                <li>
                  <div className="two" onClick={() => setMenuOpen(false)}><NavLink exact to={`${homePageUrl}about`}>About</NavLink> </div>
                </li>
                <li>
                  <div className="one" onClick={() => setMenuOpen(false)}><NavLink exact to={`${homePageUrl}products`}>Products</NavLink></div>
                </li>
                
                <li>
                  <div className="three" onClick={() => setMenuOpen(false)}><NavLink exact to={`${homePageUrl}brands`}>Brands</NavLink></div>
                </li>
                <li>
                  <div className="four" onClick={() => setMenuOpen(false)}><NavLink exact to={`${homePageUrl}projects`}>Projects</NavLink></div>
                </li>
                <li>
                  <div className="five" onClick={() => setMenuOpen(false)}><NavLink exact to={`${homePageUrl}contact`}>Contact</NavLink></div>
                </li>
                <li>
                  <div className="six" onClick={() => setMenuOpen(false)}><NavLink exact to={`${homePageUrl}experience`}>experience</NavLink></div>                    
                </li>

              </ul>
            </div>
          </nav>
          <div>
            <div className={isMenuOpen ?"nav-bg-overlay":"nav-bg"}></div>
          </div>
        </div>
      </div>
      </Container>
      
     
    </div>
    
    
    
    {/* <div>
    <Container className="btn-triger">
            <Link href="https://wa.me/+919778459789"
                tooltip="Whatsapp"
                icon="icon icon-WhatsappLogo-1" className="fab-item btn btn-link btn-lg text-white whatsapp"/>

            <Link href="tel:+91-9778459789"
                tooltip="Wall" icon="icon icon-call" className="fab-item btn btn-link btn-lg text-white call"/>
                
            <Link href="mailto:inquiry@thebathstudio.in"
            tooltip="Mail"
            icon="icon icon-mail" className="fab-item btn btn-link btn-lg text-white email"/>
              
              
            <Button
                icon="icon-plus"
                rotate={true}/>
      </Container>
    </div> */}
    </div>
  )  
};

export default Header;
