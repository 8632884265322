import { Container, Link } from 'react-floating-action-button';
import { useState } from 'react';
// import { Link } from "react-scroll";
import {
    FloatingMenu,
    MainButton,
    ChildButton,
} from 'react-floating-button-menu';
//   import MdAdd from '@material-ui/icons/add';
//   import MdClose from '@material-ui/icons/clear';

const Fltbtn = () => {
    // const ContentCutIcon = createSvgIcon(
    //     <path d="M9.64 7.64c.23-.5.36-1.05.36-1.64 0-2.21-1.79-4-4-4S2 3.79 2 6s1.79 4 4 4c.59 0 1.14-.13 1.64-.36L10 12l-2.36 2.36C7.14 14.13 6.59 14 6 14c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4c0-.59-.13-1.14-.36-1.64L12 14l7 7h3v-1L9.64 7.64zM6 8c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm0 12c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm6-7.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5.5.22.5.5-.22.5-.5.5zM19 3l-6 6 2 2 7-7V3z" />,
    //     "ContentCut"
    // );
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className="bs-fltbtn">
            <Container className="fab">
                <div className="">
                    <FloatingMenu
                        slideSpeed={500}
                        direction="up"
                        spacing={8}
                        isOpen={isOpen}
                    >
                        <MainButton
                            iconResting={<i className="icon icon-plus" style={{ fontSize: 20 }} nativeColor="white" />}
                            iconActive={<i className="icon-plus" style={{ fontSize: 25 }} nativeColor="white" />}
                            backgroundColor="black"
                            onClick={() => setIsOpen(!isOpen)}
                            size={56}
                        />
                        <ChildButton
                            icon={<Link href="https://wa.me/+919778459789"
                                tooltip="Whatsapp"
                                icon="icon icon-WhatsappLogo-1" className="fab-item btn btn-link btn-lg text-white whatsapp" />
                            }
                            backgroundColor="white"
                            size={40}
                            onClick={() => console.log('First button clicked')}
                        />
                        <ChildButton className="call-icn"
                            icon={<Link href="tel:+919920905368"
                            tooltip="Call" icon="icon icon-call" className="fab-item btn btn-link btn-lg text-white call"/>
            }
                            backgroundColor="white"
                            size={40}
                            onClick={() => console.log('First button clicked')}
                        />
                        <ChildButton
                            icon={<Link href="mailto:inquiry@thebathstudio.in"
                            tooltip="Mail"
                            icon="icon icon-mail" className="fab-item btn btn-link btn-lg text-white email"/>
                              }
                            backgroundColor="white"
                            size={40}
                            onClick={() => console.log('First button clicked')}

                        />
                        {/*<ChildButton
      icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
      backgroundColor="white"
      size={40}
    />
    <ChildButton
      icon={<MdFavorite style={{ fontSize: 20 }} nativeColor="black" />}
      backgroundColor="white"
      size={40}
    /> */}
                    </FloatingMenu>
                    {/* <div className="btn-triger">
                        <Link href="https://wa.me/+919778459789"
                            tooltip="Whatsapp"
                            icon="icon icon-WhatsappLogo-1" className="fab-item btn btn-link btn-lg text-white whatsapp"/>

                        <Link href="tel:+919920905368"
                            tooltip="Call" icon="icon icon-call" className="fab-item btn btn-link btn-lg text-white call"/>
                            
                        <Link href="mailto:inquiry@thebathstudio.in"
                        tooltip="Mail"
                        icon="icon icon-mail" className="fab-item btn btn-link btn-lg text-white email"/>
                        
                        
                        <Button
                            icon="icon-plus"
                            rotate={true}/>
                    </div> */}
                </div>
            </Container>
        </div>
    );
}

export default Fltbtn;