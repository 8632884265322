import React, { Component } from 'react';
import JuicerFeed from 'react-juicer-feed';
import { Container,Containerfluid, Row, Col, Form, FormGroup, Label, Input,Button } from 'reactstrap';

// NOTE: Juicer demo feed ID (also see: https://www.juicer.io/juicer)
//   Replace with your own Feed ID once you've set up a free feed on juicer.io
const demoFeedId = 'thebathstudio';

class App extends Component {
  render() {
    return (
        <div className="lyt-section typ-insta-sec"> 
            <Container>
            <div className="bs-heading typ-social-heading">
                <div className="title typ-cps" data-aos="slide-up" data-aos-once="false">Social Connect</div>
            </div>
            <div className="bs-social-icns">
            <ul>
                <li><a href="https://www.instagram.com/tbshome/" target="blank"> <i className="icon-InstagramLogo"></i></a></li>
                <li><a href="https://www.facebook.com/thebathstudio" target="blank"><i className="icon-FacebookLogo"></i></a></li>
            </ul>
                <JuicerFeed feedId={demoFeedId} />
        </div>
            </Container>
       

      </div>
    );
  }
}

export default App;
