import React, { useState, useRef, useEffect } from 'react';
// import { Container, Button, Link } from 'react-floating-action-button';


import { Container, Containerfluid, Row, Col } from 'reactstrap';

// import { fadeIn } from 'react-animations';

import { Swiper, SwiperSlide } from "swiper/react";


//Animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";

// import Swiper core and required modules
import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper/core';

//Swiper styles
import "swiper/swiper.scss";
import 'swiper/swiper-bundle.css';
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";

//images
import nextArrow from "../images/nextArrow.png";
import HomeSlideImage1 from '../images/Home/HomeSlideImage3A.png';
import HomeSlideImage2 from '../images/Home/HomeSlideImage1A.png';
import HomeSlideImage3 from '../images/Home/HomeSlideImage2A.png';
import { NavLink } from 'reactstrap';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);



const Home = () => {

    const ref = useRef(null);
    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext()
        }
    };
    const [showAosAnim, setShowAosAnim] = useState(false);
    const [SlideIndex,setSlideIndex] = useState(0)

    //Cursor Animation
    const [MousePosition, setMousePosition] = useState({
        left: 0,
        top: 0,
    });

    useEffect(() => {
        setShowAosAnim(true);
        Aos.init({ duration: 2000, disable: 'mobile' });
    })


    // store swiper instance
    // const [swiper, setSwiper] = useState(null);

    // const slideTo = (index) => swiper.slideTo(index);

    function handleMouseMove(ev) {
        setMousePosition({ left: ev.clientX, top: ev.clientY });
        //console.log(ev.clientX,ev.clientY);
        //console.log('Mouse Over Working');
    }

    return (
        <section className="">
            {/* Slider */}
            <Container fluid className="home-swiper">
                <div className="pg-home">
                    {/* <button className="bs-arrow button-next " onClick={goNext}>
                           <img src={nextArrow} alt="Next"/>
                        </button> */}
                    <Swiper ref={ref}
                        slidesPerView={1}
                        spaceBetween={0}
                        loop={true}
                        navigation={true}
                        mouseDrag={false}
                        draggable={false}

                        pagination={{
                            "type": "fraction"
                        }}
                        breakpoints={{
                            // when window width is >= 320px
                            0: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 480px
                            480: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 640px
                            767: {
                                slidesPerView: 1,
                            }
                        }}
                        //onInit = {()=> console.log('Initialization')}
                        onSlideChange={(swiper) => {
                            // setShowAosAnim(false);

                            // setTimeout(() => {
                            //     // setShowAosAnim(false);

                            //     setShowAosAnim(true);
                            //     // Aos.init({disable: 'mobile'});
                            //     // console.log("aos re init")
                            // }, 100)
                            setSlideIndex(swiper.realIndex)
                            console.log(swiper.realIndex, 'slide change')
                        }}
                        //onSwiper={(swiper) => console.log(swiper)}
                        //onSlideActive = {()=> console.log('Active slide')}
                        className="mod-Swiper1">

                        <div className="constantValues">
                            <h2 className="slideZeroConstant">0</h2>
                        </div>
                        <SwiperSlide className="firstSlide">
                            <h2 className="slideNumber">1</h2>
                            <div className="slideHeading">
                                <div className="title">
                                    {/* <div>
                                            <h1>Live with inspiration </h1>
                                        </div> */}
                                    {SlideIndex ==0 ?

                                        <h1 className="animTitle">
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="900">  L </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="950">   i </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1000">   v </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1050">    e </span>
                                            &nbsp;
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1100">     w </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1150">     i </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1200">     t </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1250">     h</span>
                                            &nbsp;
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1300">    i </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1350">   n </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1400">   s </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1450">    p </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1500">    i </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1550">    r </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1600">    a </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1650">    t </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1700">    i </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1750">    o </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1800">    n </span>
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1800">    . </span>
                                        </h1>
                                        :
                                        <h1>Live with inspiration. </h1>}

                                    {/* <div>
                                            <h1> <span >L</span><span  >i</span><span  >v</span><span  >e </span> 

                                            <span > w</span><span  >i</span><span  >t</span><span  >h </span>

                                            <span  >
                                             </span><span> i</span><span>n</span><span>s</span><span>p</span><span>i</span><span>r</span><span>a</span><span>t</span><span>i</span><span>o</span><span>n.</span></h1>
                                        </div> */}

                                    {/* <div>
                                            <h1> <span data-aos="fade-up" data-aos-once="false" data-aos-delay="200">L</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="300">i</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="400">v</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="500">e </span> 

                                            <span data-aos="fade-up" data-aos-once="false" data-aos-delay="600"> w</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="700">i</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="800">t</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="900">h </span>

                                            <span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1000">
                                             </span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1100"> i</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1200">n</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1300">s</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1400">p</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1500">i</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1600">r</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1700">a</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1800">t</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="1900">i</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="2000">o</span><span  data-aos="fade-up" data-aos-once="false" data-aos-delay="2100">n.</span></h1>
                                        </div> */}
                                </div>
                                <div className="subtitle">
                                    <div>
                                        <p>Holistic solutions for your home.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="slideImage3">
                                {/* <img src={HomeSlideImage1} alt="Slider 1" /> */}
                            </div>

                        </SwiperSlide>
                        <SwiperSlide className="secondSlide">
                            {/* <span className="slideCount">02</span> */}
                            <h2 className="slideNumber">2</h2>
                            <div className="slideHeading">
                                <div className="title">
                                    <div>
                                        {/* <h1>Create spaces to live in.</h1> */}
                                        {SlideIndex ==1?
                                            <h1 className="animTitle">
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="900">C</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="950">r</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1000">e</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1050">a</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1100">t</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1150">e</span>
                                                &nbsp;
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1200">s</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1250">p</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1300">a</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1350">c</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1400">e</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1450">s</span>
                                                &nbsp;
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1500">t</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1550">o</span>
                                                &nbsp;
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1600">l</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1650">i</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1700">v</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1750">e</span>
                                                &nbsp;
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1800">i</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1850">n</span>
                                                
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1850">.</span>
                                            </h1>
                                            :
                                            <h1>Create spaces to live in.</h1>
                                        }
                                    </div>
                                </div>
                                <div className="subtitle">                                  
                                    <div>
                                        <p>And be inspired by.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="slideImage1">
                                {/* <img src={HomeSlideImage2} alt="slider 2"/> */}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="thirdSlide">
                            {/* <span className="slideCount">03</span> */}
                            <h2 className="slideNumber">3</h2>
                            <div className="slideHeading">
                                <div className="title">
                                    <div>
                                        {/* <h1>Imagine.</h1> */}
                                        {SlideIndex ==2 ?
                                            <h1 className="animTitle">
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="900">I</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="950">m</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1000">a</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1050">g</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1100">i</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1150">n</span>
                                                <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1200">e</span>
                                                
                                            <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1250">.</span>
                                                &nbsp;
                                                {/* <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1200">     s </span>
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1250">     p </span>                                             
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1300">    a </span>
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1350">   c </span>
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1400">   e </span>
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1450">    s </span>
                                            &nbsp;
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1500">    t </span>
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1550">    o </span>
                                            &nbsp;
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1600">    l </span>
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1650">    i </span>
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1700">    v </span>
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1750">    e </span>
                                            &nbsp;
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1800">    i </span>
                                            <span data-aos="fade-up"  data-aos-duration="200" data-aos-delay="1850">    n </span> */}
                                            </h1>
                                            : <h1>Imagine.</h1>
                                        }

                                    </div>
                                </div>
                                <div className="subtitle">
                                    <div>
                                        <p>And transform. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="slideImage2">
                                {/* <img src={HomeSlideImage3} alt="slider 3"/> */}
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    {/* <button onMouseMove={(ev)=> handleMouseMove(ev)}
                        style={{left:MousePosition.left , top: MousePosition.top}}>hello</button> */}

                    {/* <div className="cursor" onMouseMove={(ev)=> handleMouseMove(ev)}
                        style={{left:MousePosition.left , top: MousePosition.top}}></div>
                        <div className="cursor2" onMouseMove={(ev)=> handleMouseMove(ev)}
                        style={{left:MousePosition.left , top: MousePosition.top}}>Explore</div>
                        <div className="cursor3"></div>
                        <div className="cursor4"></div> */}
                </div>
            </Container>

        </section>
    );
};

export default Home;
