import {NavLink } from "react-router-dom";

import { Container,Containerfluid, Row, Col } from 'reactstrap';

import { useHistory } from "react-router-dom";
//Images
import backArrow from "../images/backArrow.png";
import brandImage11 from "../images/Brands/brandImage1111.png";

import bd11Img from "../images/Brands/casa-santorini-pyrgos-estate-9retocada-scaled.jpg";

import bd11Img1 from "../images/Brands/bd11Img1.png";
import bd11Img2 from "../images/Brands/bd11Img2.png";
import bd11Img3 from "../images/Brands/bd11Img3.png";

import brandDownload from "../images/brandDownload.png";

import DesignQuarryCatalogue from "../pdf/design_quarry_catalogue.pdf";

// import DesignQuarryCatalogue from "../pdf/Vaillant_Master_Catalog.pdf";

const Design_quarry = (props) =>{
    let history = useHistory();
    return(
            <div className="">
                   <Container fluid className="brand-detl">
        <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                        <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                            <i className="icon icon-Line"></i>                        
                    </div>
                        <div className="content">
                        <img src={brandImage11} alt="Brand Image 1" className="d-quarryimg" />
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">
                        A branch of Jain Marble industries, Concrete Works India is making its mark as a decorative concrete surfaces company. As part of its offering is a range of products that are CE certified and imported from Europe. A global trend presently, its products use a coating of concrete capable of adapting to any surface - including floors, walls, ceilings, bathrooms, terraces, facades and even furniture. </p>

                        <p className="bs-para typ-normal para2">
                        Ideal for modern and minimalist spaces, it’s the perfect way to add a touch of long lasting sophistication with classic, rustic or antique finishes. Featuring in sites across Pune, Mumbai, Hyderabad, Bengaluru, Delhi, Kohlapur and Nagpur - Concrete Works has had the opportunity to execute visions of renowned architects and designers with varied design philosophies.
                        </p>

                        <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal para3">
                        TBS forayed into surfaces in the year 2019, with Concrete Works India - a division of Design Quarry. The association began when TBS recognised that seamless concrete surfaces for walls and floors happened to be the preferred selection of designers and clients alike. Given the category’s aesthetic appeal, it meant facilitating a solution that was natural, customised and unique. 
                        </p>

                        <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                           
                                <a href={DesignQuarryCatalogue} download="DesignQuarryCatalogue" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                            Design&nbsp;Quarry&nbsp;Catalogue</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        {/* <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Kohler&nbsp;Master&nbsp;Catalogue</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        <div className="catalogue-desc bs-para">
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Kohler&nbsp;Vibrant&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        <div className="catalogue-desc bs-para"> 
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>*/}
                    
                    </div>
                    </Col>  

<Col lg={6} md={12} sm={12}>
                    <div className="image">
                        <img src={bd11Img} alt="Artist Edition - Kohler1"/>
                    </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Design_quarry;