import {NavLink } from "react-router-dom";

import { useHistory } from "react-router-dom";
//Images
import backArrow from "../images/backArrow.png";
import brandImage4 from "../images/Brands/brandImage4.png";
import brandDownload from "../images/Brands/brandDownload.png";

import { Container,Containerfluid, Row, Col } from 'reactstrap';


import AxorCatelogue1 from "../pdf/axor-1.pdf";
import AxorCatelogue2 from "../pdf/axor-2.pdf";

const Axor = (props) =>{
    let history = useHistory();
    return(
        <div className="">
                  
        <Container fluid className="brand-detl">
                        <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                    <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                        <i className="icon icon-Line"></i>                        
                </div>
                    <div className="content">
                        <img src={brandImage4} alt="Brand Image 1"/>
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">                         
                            A brand of the Hansgrohe Group, AXOR was launched in 1993. Its mission was to develop taps and showers with unparalleled design, technology and manufacturing quality. The brand has been guided by three core values: avangarde, personalisation and perfection. Today, AXOR is one of the leading lights of the group, and has established its presence in 140 countries.
                        </p>

                        <p className="bs-para typ-normal para2">
                        The brand made a full fledged entry into India, in 2017, when Hansgrohe India unveiled its first exclusive AXOR showroom in association with its longstanding partner C. Bhogilal West End, in Mumbai - showcasing some of the finest collections, developed in collaboration with world renowned architects and interior designers. 
                        </p>

                        <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal para3">
                        The association between TBS and Hansgrohe began in 2017 and today has culminated in a showcase of its exclusive range of showering systems, which include the prestigious Axor range of products.
                        </p>
                        
                        <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                        
                                <a href={AxorCatelogue1} download="AxorCatelogue1c" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Axor&nbsp;Master&nbsp;Catalogue</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        <div className="catalogue-desc bs-para">
                           
                                <a href={AxorCatelogue2} download="AxorCatelogue2" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Axor&nbsp;My&nbsp;Edition&nbsp;Brochure</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        {/* <div className="catalogue-desc bs-para">
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                    </div>
                    </Col>  

<Col lg={6} md={12} sm={12}   >
                    <div className="image">
                        <iframe src="https://youtube.com/embed/bvQu28SnBgc" title="description" width="100%" height="100%"></iframe>
                    </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Axor;