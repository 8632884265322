import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { Container, Row, Col } from 'reactstrap';
import { homePageUrl } from '../module/Constants.js';

import Aos from "aos";
import "aos/dist/aos.css";
import { SRLWrapper } from "simple-react-lightbox";

//Images
import backArrow from "../images/backArrow.png";

import Door1 from "../images/Products/Door1.jpg";
import Door2 from "../images/Products/Door2.jpg";
import Door3 from "../images/Products/Door3.jpeg";
import Door4 from "../images/Products/Door4.jpeg";
import Door5 from "../images/Products/Door5.jpeg";
import Door6 from "../images/Products/Door6.jpeg";

import Door_Large1 from "../images/Products/large/Door1.jpg";
import Door_Large2 from "../images/Products/large/Door2.jpg";
import Door_Large3 from "../images/Products/large/Door3.jpeg";
import Door_Large4 from "../images/Products/large/Door4.jpeg";
import Door_Large5 from "../images/Products/large/Door5.jpeg";
import Door_Large6 from "../images/Products/large/Door6.jpeg";

const Doors = () => {

    useEffect(() => {
        Aos.init({ duration: 2000, delay: 2 });
    });

    return (
        <div className="lyt-section">
           
            <Container>
                <div className="bs-hero">
                    <Row>
                        <Col>
                            <div className="bs-arrow back">
                                {/* <NavLink exact to="/clients/tbs-website/products"><i className="icon icon-Line"></i></NavLink> */}
                                <NavLink exact to={`${homePageUrl}products`}><i className="icon icon-Line"></i></NavLink>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} >
                <div className="heading">
                    <span className="title">Doors<sup>06</sup></span>
                </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                <div className="description">
                    <div>
                    <h3 className="desc-title">Make an opening statement.</h3>
                    </div>
                    <div>
                        <p>
                            Our innovative portfolio of door concepts for home or commercial use provide the ideal way to make a statement. Available in a range of attractive standard or custom finish options, these have been created to draw the perfect balance between craftsmanship, durability and space optimising design.
                        </p>
                    </div>
                   
                    <div className="feature-details">
                        <div>
                            <span className="specification">Featured Brands:&nbsp;</span>
                            <span className="spec-detail1">
                                <NavLink exact to={`${homePageUrl}ritikaawood`} className="detail-link">Ritikaawood</NavLink>
                                
                                {/* <NavLink exact to="/clients/tbs-website/ritikaawood" className="detail-link">Ritikaawood</NavLink> */}
                                </span>
                        </div>
                    </div>
                    <div>
                        <div class="feature-details">
                            <span className="specification">Materials:&nbsp;</span>
                            <span className="spec-detail2">Wood</span>
                        </div>
                    </div>

                </div>
                </Col>
                    </Row>
            </div>
            <SRLWrapper>
                <div className="bs-masonry">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 992: 3 }} >
                        <Masonry gutter="20px">
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                                <a href={Door_Large1}>
                                    <img src={Door1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                            <a href={Door_Large2}>
                                    <img src={Door2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                            <a href={Door_Large3}>
                                    <img src={Door3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                            <a href={Door_Large4}>
                                    <img src={Door4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                            <a href={Door_Large5}>
                                    <img src={Door5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                            <a href={Door_Large6}>
                                    <img src={Door6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </SRLWrapper>
            </Container>
        </div>
    );
}

export default Doors;