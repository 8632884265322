import {NavLink } from "react-router-dom";


import { Container,Containerfluid, Row, Col } from 'reactstrap';

import { useHistory } from "react-router-dom";
//Images
import backArrow from "../images/backArrow.png";
import brandImage8 from "../images/Brands/brandImage8.png";

import brandDownload from "../images/Brands/brandDownload.png";

import Ebaracatelogue from "../pdf/ebara.pdf";

const Ebara = (props) =>{
    let history = useHistory();
    return(
        <div className="">
        <Container fluid className="brand-detl">
        <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                        <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                            <i className="icon icon-Line"></i>                        
                    </div>
                        <div className="content">
                        <img src={brandImage8} alt="Brand Image 1"/>
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">
                        Ebara Corporation is a publicly traded company based in Tokyo, Japan, and was founded in 1912. It designs, manufactures, and maintains the operation of infrastructure and industrial machinery. Over time, the company has come to be recognised for developing an industry standard variety of products ranging from water pressure pumps to blowers, chillers and waste water machinery. Its global capabilities are supported by 18 service centres across China, Taiwan, Japan, Europe, the Middle East, and North and South America with regional engineering offices in Singapore, Switzerland, and the US.</p>

                        <p className="bs-para typ-normal para2">
                        Ebara entered India in 2013 and complemented its long term presence with the establishment of a 3,530 sq.mtr. service centre in the Bidadi Industrial area, southwest of Bengaluru, in the year 2015.
                        </p>

                        <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal para3">
                        TBS’ association with Ebara via Aquagreen dates back to 2018. As a sales and service channel partner, Aquagreen understands the importance of partnering with those that help offer turnkey solutions in the space. This even includes water pressure - which goes a long way in delivering the desired bathroom experience. It is a solution that Ebara provides with global expertise.
                        </p>
                        
                        <div className="catalogue-desc bs-para">
                            
                                <a href={Ebaracatelogue} download="Ebaracatelogue" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Ebara&nbsp;Master&nbsp;Catalogue</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        {/* <div className="catalogue-desc bs-para">
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Kohler&nbsp;Vibrant&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        <div className="catalogue-desc bs-para">
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                    </div>
                    </Col>  

                <Col lg={6} md={12} sm={12}>
                    <div className="image">
                    {/* <iframe src="https://aquagreensolutions.in/wp-content/uploads/demo_files/ebara-en.mp4
" title="description" width="100%" height="100%"></iframe> */}

                            <iframe src="https://youtube.com/embed/Ag1fHyxtu8s" title="description" width="100%" height="100%"></iframe>

                        {/* <iframe src="https://aquagreensolutions.in/wp-content/uploads/demo_files/ebara-en.mp4" title="description" width="100%" height="100%"></iframe> */}
                        </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
}

export default Ebara;