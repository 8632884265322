import {NavLink } from "react-router-dom";


import { Container,Containerfluid, Row, Col } from 'reactstrap';

import { useHistory } from "react-router-dom";
//Images
import backArrow from "../images/backArrow.png";
import brandImage7 from "../images/Brands/brandImage7.png";

import brandDownload from "../images/Brands/brandDownload.png";

import VaillantCatelogue from "../pdf/Vaillant_Master_Catalog.pdf";

const Vaillant = (props) =>{
    let history = useHistory();
    return(
        <div className="">
        <Container fluid className="brand-detl">
        <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                        <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                            <i className="icon icon-Line"></i>                        
                    </div>
                        <div className="content">
                        <img src={brandImage7} alt="Brand Image 1"/>
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">Vaillant was founded by Johann Vaillant all the way back in 1874 in Remscheid, Germany. Ever since then, the company has become a market leader in the heating and ventilation technology industry. Always innovating to create products that are efficient and environment friendly, Vaillant is shaping the future of heating technology.</p>

                        <p className="bs-para typ-normal para2">
                        Its global presence has been established with a foray into key European markets as well as China, India, and 60 countries worldwide.
                        </p>

                        <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal para3">
                        TBS is associated with Vaillant since 2018. This association is managed through Aquagreen - a brand that also happens to be the exclusive national partner for Vaillant in India. At present, TBS currently features and markets Vaillant’s full range of tankless water heaters and storage heat pumps.

                        </p>
                        
                        <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                            
                                <a href={VaillantCatelogue} download="VaillantCatelogue" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                            Vaillant&nbsp;Master&nbsp;Catalogue</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        {/* <div className="catalogue-desc bs-para">
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Kohler&nbsp;Vibrant&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        <div className="catalogue-desc bs-para">
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                    </div>
                    </Col>  

                <Col lg={6} md={12} sm={12}>
                    <div className="image">
                        <iframe src="https://youtube.com/embed/AxCBIxxjAjk" title="description" width="100%" height="100%"></iframe>
                        </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Vaillant;