import {NavLink } from "react-router-dom";

import { useHistory } from "react-router-dom";
//Images
import backArrow from "../images/backArrow.png";
import brandImage3 from "../images/Brands/brandImage3.png";
import brandDownload from "../images/Brands/brandDownload.png";

import { Container,Containerfluid, Row, Col } from 'reactstrap';

import CansgorheCatelogue from "../pdf/hansgorhe.pdf";

const Hangsrohe = (props) =>{
    let history = useHistory();
    return(
            <div className="">
                  
                <Container fluid className="brand-detl">
                <div className="bs-brand-detail">
        <Row className="brand-img-detl">
            <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
            <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                <i className="icon icon-Line"></i>                        
        </div>
                <div className="content">
                        <img src={brandImage3} alt="Brand Image 1"/>
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">
                        A company steeped in tradition, Hansgrohe was founded in 1901 with a metal pressing workshop in Schilltach, in the Black Forest. The company headquarters are still based there.</p>

                        <p className="bs-para typ-normal para2">
                        Today, the brand enjoys
                         a presence in nearly 180 countries and has cemented its global 
                         reputation as the world’s leading manufacturer of bathroom fittings 
                         and kitchen taps and sinks. Hansgrohe has been tending to the Indian 
                         market since 1999, through an active association with Jaquar and company, 
                         post which the company set up its fully owned subsidiary in 2007. 
                        </p>

                        <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal para3">
                        The association between TBS and Hansgrohe began in 
                        2017 and today has culminated in a showcase of its exclusive 
                        range of showering systems, which include the prestigious Axor 
                        range of products.
                        </p>
                        
                        <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                           
                                <a href={CansgorheCatelogue} download="CansgorheCatelogue" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                            Hansgrohe&nbsp;Master&nbsp;Catalogue</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        {/* <div className="catalogue-desc bs-para">
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Kohler&nbsp;Vibrant&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        <div className="catalogue-desc bs-para">
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                    </div>
                    </Col>  

                    <Col lg={6} md={12} sm={12}   >
                        <div className="image">
                        <iframe src="https://youtube.com/embed/4LYWHodM2OU?controls=0" title="description" width="100%" height="100%"></iframe>
                    </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Hangsrohe;