import React, { useRef, useEffect } from 'react';
import { TweenMax, Power3 } from 'gsap';
import { TransitionGroup, Transition } from "react-transition-group";
import { Switch, Route,Redirect } from "react-router-dom";

//Components
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import Fltbtn from "../components/Fltbtn.js";

//sub-pages
import Testimonials from "../pages/Testimonials.js";

import Social_connect from "../pages/Social_connect.js";

//Pages
import Home from "../pages/Home.js";
import Products from "../pages/Products.js";
//sub-pages
import Bathroom from "../pages/Bathroom.js";
import Doors from "../pages/Doors.js";
import Windows from "../pages/Windows.js";
import Facades from "../pages/Facades.js";
import Railing from "../pages/Railing.js";
import Surfaces from "../pages/Surfaces.js";
import Watersolutions from "../pages/Watersolutions.js";

import About from "../pages/About.js";
import Brands from "../pages/Brands.js";
//sub-pages
import Kolher from "../pages/Kolher.js";
import Artist_edition from "../pages/Artist_edition.js";
import Hangsrohe from "../pages/Hangsrohe.js";
import Axor from "../pages/Axor.js";
import Fenesta from "../pages/Fenesta.js";
import Ritikaawood from "../pages/Ritikaawood.js";
import Vaillant from "../pages/Vaillant.js";
import Ebara from "../pages/Ebara.js";
import Smith from "../pages/Smith.js";
import Waterfileration from "../pages/Waterfileration.js";
import Design_quarry from "../pages/Design_quarry.js";
import Zanskar from "../pages/Zanskar.js";
// import kolher2 from "../pages/kolher2.js";

import Projects from "../pages/Projects";
//sub-pages
import Architect_dhruva_samal_bathrooms_projects from "../pages/Architect_dhruva_samal_bathrooms_projects.js";
import Architect_prakash_jhadav_sangli from "../pages/Architect_prakash_jhadav_sangli.js";
import Projectdetail3 from "../pages/Projectdetail3.js";
import Projectdetail4 from "../pages/Projectdetail4.js";
import Pravir_sethi_kp_private_residence from "../pages/Pravir_sethi_kp_private_residence.js";
import Projectdetail6 from "../pages/Projectdetail6.js";

import Contact from "../pages/Contact.js";
import Experience from "../pages/Experience.js";
import ErrorPage from '../pages/ErrorPage.js';
import Sitemap from '../pages/Sitemap.js';

//Trial page for slider and other animation
import Trial from "../pages/Trial.js";

import { gsap } from "gsap";
import { homePageUrl } from '../module/Constants.js';
const completeCall = (target, parent) => {
  gsap.set(target, { clearProps: "position, width" });
  parent && gsap.set(parent, { clearProps: "overflow" });
};

const NotFoundRedirect = () => <Redirect to={`${homePageUrl}404`} />


const Routes = (props) => {
  const parentNode = useRef(null);

  const onEnterHandler = (node) => {
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
      // position: "absolute", left: 0,
      // x: 100, autoAlpha: 0
    });
    gsap.set(parentNode.current, { overflow: "hidden" })
    // Create the animation for the incoming component
    gsap.to(node, {
      // duration: 0.4, autoAlpha: 1,
      // x: 0,
      onComplete: completeCall,
      onCompleteParams: [node, parentNode.current]
    });
  };

  const onExitHandler = (node) => {
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
      // position: "absolute", left: 0
    });
    // Create the animation for the incoming component
    gsap.to(node, {
      // duration: 0.4, autoAlpha: 0,
      // x: -100
    });
  };

  useEffect(() => {
    //Overlay
    TweenMax.to(".bs-overlay", 1.5, {
      delay: .1,
      bottom: "100%",
      ease: Power3.easeInOut
    });
  }, [])

  return (
    <div>
      {/* OVERLAY*/}
      <div class="bs-overlay"></div>
      <div className="page-start">
        <Header />
        <div
          className="routes"
          ref={parentNode}
        >
          <TransitionGroup component={null}>
            <Transition
              timeout={500}
              key={props.location.pathname}
              onEnter={onEnterHandler}
              onExit={onExitHandler}
            >
              <Switch location={props.location}>

                {/* <Route path="/clients/tbs-website/" exact>
                      <Home />
                    </Route>
                    <Route path="/clients/tbs-website/about">
                      <About />
                    </Route>
                      <Route path="/clients/tbs-website/products">
                        <Products />
                      </Route>
                        <Route path="/clients/tbs-website/bathroom">
                          <Bathroom />
                        </Route>
                        <Route path="/clients/tbs-website/doors">
                          <Doors />
                        </Route>
                        <Route path="/clients/tbs-website/windows">
                          <Windows />
                        </Route>
                        <Route path="/clients/tbs-website/facades">
                          <Facades />
                        </Route>
                        <Route path="/clients/tbs-website/railing">
                          <Railing />
                        </Route>
                        <Route path="/clients/tbs-website/surfaces">
                          <Surfaces />
                        </Route> 
                        <Route path="/clients/tbs-website/watersolutions">
                          <Watersolutions />
                        </Route> 
                    <Route path="/clients/tbs-website/brands">
                      <Brands />
                    </Route>
                      <Route path="/clients/tbs-website/kolher">
                        <Kolher />
                      </Route>
                      <Route path="/clients/tbs-website/artist_edition">
                        <Artist_edition />
                      </Route>
                      <Route path="/clients/tbs-website/hangsrohe">
                        <Hangsrohe />
                      </Route>
                      <Route path="/clients/tbs-website/axor">
                        <Axor />
                      </Route>
                      <Route path="/clients/tbs-website/fenesta">
                        <Fenesta />
                      </Route>
                      <Route path="/clients/tbs-website/ritikaawood">
                        <Ritikaawood />
                      </Route>
                      <Route path="/clients/tbs-website/vaillant">
                        <Vaillant />
                      </Route>
                      <Route path="/clients/tbs-website/ebara">
                        <Ebara />
                      </Route>
                      <Route path="/clients/tbs-website/smith">
                        <Smith />
                      </Route>
                    <Route path="/clients/tbs-website/waterfileration">
                        <Waterfileration />
                      </Route>
                      <Route path="/clients/tbs-website/design_quarry">
                        <Design_quarry />
                      </Route>
                      <Route path="/clients/tbs-website/kolher2">
                        <kolher2 />
                      </Route> 
                    <Route path="/clients/tbs-website/projects">
                      <Projects />
                    </Route>
                      <Route path="/clients/tbs-website/architect_dhruva_samal_bathrooms_projects">
                        <Architect_dhruva_samal_bathrooms_projects />
                      </Route>
                      <Route path="/clients/tbs-website/architect_prakash_jhadav_sangli">
                        <Architect_prakash_jhadav_sangli />
                      </Route>
                      <Route path="/clients/tbs-website/projectdetail4">
                        <Projectdetail4 />
                      </Route>
                      <Route path="/clients/tbs-website/projectdetail4">
                        <Projectdetail4 />
                      </Route>
                      <Route path="/clients/tbs-website/pravir_sethi_kp_private_residence">
                        <Pravir_sethi_kp_private_residence />
                      </Route>
                      <Route path="/clients/tbs-website/projectdetail6">
                        <Projectdetail6 />
                      </Route>
                    <Route path="/clients/tbs-website/contact">
                      <Contact />
                    </Route>
                    <Route path="/clients/tbs-website/experience">
                      <Experience />
                    </Route> 
                      <Route path="/clients/tbs-website/testimonials">
                        <Testimonials/>
                      </Route> 
                       <Route path="/clients/tbs-website/social_connect">
                        <Social_connect/>
                      </Route> 
                      
                      <Route path="/clients/tbs-website/fltbtn">
                        <Fltbtn/>
                      </Route>

                      

                      <Route path="/clients/tbs-website/trial">
                        <Trial/>
                      </Route>  */}






                <Route path={homePageUrl} exact>
                  <Home />
                </Route>
                <Route path={`${homePageUrl}about`}>
                  <About />
                </Route>
                <Route path={`${homePageUrl}products`}>
                  <Products />
                </Route>
                <Route path={`${homePageUrl}bathroom`}>
                  <Bathroom />
                </Route>
                <Route path={`${homePageUrl}doors`}>
                  <Doors />
                </Route>
                <Route path={`${homePageUrl}windows`}>
                  <Windows />
                </Route>
                <Route path={`${homePageUrl}facades`}>
                  <Facades />
                </Route>
                <Route path={`${homePageUrl}railing`}>
                  <Railing />
                </Route>
                <Route path={`${homePageUrl}surfaces`}>
                  <Surfaces />
                </Route>

                <Route path={`${homePageUrl}watersolutions`}>
                  <Watersolutions />
                </Route>

                <Route path={`${homePageUrl}brands`}>
                  <Brands />
                </Route>
                <Route path={`${homePageUrl}kolher`}>
                  <Kolher />
                </Route>
                <Route path={`${homePageUrl}artist_edition`}>
                  <Artist_edition />
                </Route>
                <Route path={`${homePageUrl}hangsrohe`}>
                  <Hangsrohe />
                </Route>
                <Route path={`${homePageUrl}axor`}>
                  <Axor />
                </Route>
                <Route path={`${homePageUrl}fenesta`}>
                  <Fenesta />
                </Route>
                <Route path={`${homePageUrl}ritikaawood`}>
                  <Ritikaawood />
                </Route>
                <Route path={`${homePageUrl}vaillant`}>
                  <Vaillant />
                </Route>
                <Route path={`${homePageUrl}ebara`}>
                  <Ebara />
                </Route>
                <Route path={`${homePageUrl}smith`}>
                  <Smith />
                </Route>
                <Route path={`${homePageUrl}zanskar`}>
                  <Zanskar />
                </Route>
                <Route path={`${homePageUrl}waterfileration`}>
                  <Waterfileration />
                </Route>
                <Route path={`${homePageUrl}design_quarry`}>
                  <Design_quarry />
                </Route>
                <Route path={`${homePageUrl}kolher2`}>
                  <kolher2 />
                </Route>

                <Route path={`${homePageUrl}projects`}>
                  <Projects />
                </Route>
                <Route path={`${homePageUrl}architect_dhruva_samal_bathrooms_projects`}>
                  <Architect_dhruva_samal_bathrooms_projects />
                </Route>
                <Route path={`${homePageUrl}architect_prakash_jhadav_sangli`}>
                  <Architect_prakash_jhadav_sangli />
                </Route>
                <Route path={`${homePageUrl}projectdetail3`}>
                  <Projectdetail3 />
                </Route>
                <Route path={`${homePageUrl}projectdetail4`}>
                  <Projectdetail4 />
                </Route>
                <Route path={`${homePageUrl}pravir_sethi_kp_private_residence`}>
                  <Pravir_sethi_kp_private_residence />
                </Route>
                <Route path={`${homePageUrl}projectdetail6`}>
                  <Projectdetail6 />
                </Route>
                <Route path={`${homePageUrl}contact`}>
                  <Contact />
                </Route>
                <Route path={`${homePageUrl}experience`}>
                  <Experience />
                </Route>

                <Route path={`${homePageUrl}testimonials`}>
                  <Testimonials />
                </Route>

                <Route path={`${homePageUrl}social_connect`}>
                  <Social_connect />
                </Route>

                <Route path={`${homePageUrl}sitemap`}>
                  <Sitemap/>
                </Route> 
                
                <Route path={`${homePageUrl}trial`}>
                        <Trial/>
                </Route> 

                <Route path={`${homePageUrl}404`}>
                  <ErrorPage/>
                </Route> 
                    
                <Route component={NotFoundRedirect} >
                  
                </Route> 
                  </Switch>
                </Transition>
              </TransitionGroup>
      </div>
      <Footer />
      <Fltbtn />
    </div>
    </div >
  );
};

export default Routes;
