import Instagram from "../images/InstagramLogo.svg";
import Pinterest from "../images/PinterestLogo.svg";
import { NavLink } from "react-router-dom";

import { homePageUrl } from '../module/Constants.js';
// import {Container,Button, Link } from 'react-floating-action-button';
import {Container, Row, Col,Button } from 'reactstrap';

const Footer= () => {
    return(
        <div className="bs-footer">
              <Container>
                  <div className="footer-content">
                  
                      <Row>
          <Col lg={2} md={2} sm={6} xs={6}>
            <ul className="left-footer">

                {/* <li className="first"><NavLink exact to="/clients/tbs-website/testimonials">testimonials</NavLink></li> */}
                {/* <li><NavLink exact to="/clients/tbs-website/newsandmedia">new&nbsp;&amp;&nbsp;media</NavLink></li>
                <li><NavLink exact to="/clients/tbs-website/blog">blog</NavLink></li> */}

                <li className="first"><NavLink exact to={`${homePageUrl}testimonials`}>testimonials</NavLink></li>
                {/* <li><NavLink exact to="/newsandmedia">new&nbsp;&amp;&nbsp;media</NavLink></li>
                <li><NavLink exact to="/blog">blog</NavLink></li> */}
            </ul>
            </Col>
            <Col lg={10} md={10}  sm={6} xs={6}>
            <ul className="right-footer">
                
            {/* <li><NavLink exact to="/clients/tbs-website/social_connect" >Social Connect</NavLink></li> */}
            {/* <li><NavLink exact to="/social_connect">Social Connect</NavLink></li> */}

            {/* <li><NavLink exact to="/clients/tbs-website/social_connect"> <i className="icon-InstagramLogo"></i></NavLink></li>
                <li><NavLink exact to="/clients/tbs-website/social_connect"><i className="icon-FacebookLogo"></i></NavLink></li> */}


                <li><NavLink exact to={`${homePageUrl}social_connect`}> <i className="icon-InstagramLogo"></i></NavLink></li>
                <li><NavLink exact to={`${homePageUrl}social_connect`}><i className="icon-FacebookLogo"></i></NavLink></li>
                
                {/* <li><NavLink exact to="/"><img src={Pinterest} alt="Pinterest"/></NavLink></li> */}
            </ul>
            </Col>
            
            </Row>
                  
                  </div>
             
            </Container>
        </div>
        
    )
}
export default Footer;