import { BrowserRouter as Router, Route } from "react-router-dom";
import Routes from "./routes/routes";
import './App.scss';
import SimpleReactLightbox from 'simple-react-lightbox';

function App() {
  return (
  <SimpleReactLightbox>
    <Router>
      <Route path="/" component={Routes} />
    </Router>
  </SimpleReactLightbox>
  );
}

export default App;