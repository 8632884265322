import { useState , useEffect} from "react";
import { NavLink } from "react-router-dom";
import whatsappLogo from "../images/whatsappLogo.png";
import nextArrow from "../images/nextArrow.png";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { InlineWidget } from "react-calendly";
import emailjs from 'emailjs-com';
import swal from 'sweetalert';
import { useForm } from "react-hook-form";

import { Container, Row, Col,} from 'reactstrap';


import Aos from "aos";
import "aos/dist/aos.css";

const Contact = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    Aos.init({ duration: 1000, disable: 'mobile', delay: 1 });
});

const sendEmail = (e) => {
    emailjs.sendForm('service_k8o2orj', 'template_e7ky9rh',".contact-form", 'user_IO7mwHVUwXnbdy43xCvyI')
      .then((result) => {
        swal("Form submitted", "We will reach you in short time", "success");
        window.setTimeout(function(){window.location.reload()},3000)
      }, (error) => {
          console.log(error.text);
      });
  }



  return (
    <div className="lyt-section cont-sec">
          <Container>
          <Row>
            <Col>
              <div className="bs-heading sm-center" data-aos="slide-up" data-aos-once="false" data-aos-delay="50">
                <h2 className="title typ-cps typ-contact">Contact us</h2>
              </div>
            </Col>
            </Row>
        {/* <div className="bs-heading sm-center" >
            <div className="title typ-cps typ-contact">Contact us</div>
        </div> */}
            <div className="bs-flex typ-contact">
                
              <div>

            <h2 className="cont-title">Let’s be in touch.</h2>
                <p className="bs-para typ-normal cont-info" style={{paddingBottom:'0'}}>
                We’re always looking for new ways to inspire people. And nothing can help us more than 
                you writing in with some ideas, comments or even feedback that we can use. 
                Simply write into us at: <a href="mailto:inquiry@thebathstudio.in">inquiry@thebathstudio.in</a> or fill in the form below. 
                You could also reach on us <a href="https://wa.me/+919920905368">+91 99209 05368</a>

                {/* <span><i className="whatsappicon icon-WhatsappLogo-1"></i></span> */}
                {/* For general inquiries, please fill out information below. One of our associates will contact you as soon as possible. */}
                {/* We’re always looking for new ways to inspire people. And nothing can help us more than you writing in with some ideas, comments or even feedback that we can use. Simply write into us at: inquiries@tbs.com or fill in the form below. You could also reach on us +91 99209 05368. */}
                
                {/* <img src={whatsappLogo} alt="Whatsapp Logo" /> */}
                </p>
                <form onSubmit={handleSubmit(sendEmail) }  className="bs-form contact-form">
                    <input type="text" name="name" placeholder="name*" className="form-control" data-aos="fade-up" data-aos-once="false" data-aos-delay="50" {...register("name", { required: true })}/>
                    {errors.name?.type === 'required' && <div className="error" style={{ color:"red" }}>This field is required</div>}

                    <input type="text" name="email" placeholder="email*" className="form-control" autocomplete="off" data-aos="fade-up" data-aos-once="false" data-aos-delay="900" {...register("email", { required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address"
                    } })}/>                
                    {errors.email?.type === 'required' && <div className="error" style={{ color:"red" }}>This field is required</div>}
                    {errors.email?.type === 'pattern' && <div className="error" style={{ color:"red" }}>invalid email address</div>}
                    <input type='text' name="mobile" max="10" min="10" placeholder="mobile&nbsp;number*" className="form-control" data-aos="fade-up" data-aos-once="false" data-aos-delay="100" {...register("mobile", { required: true,pattern: {
        value: /^[0-9]+$/,
        message: "invalid number"
    },max: 10 })}/> 
    {errors.mobile?.type === 'required' && <div  className="error" style={{ color:"red" }}>This field is required</div>}
    {errors.mobile?.type === 'pattern' && <div  className="error" style={{ color:"red" }}>Invalid number</div>}
    {errors.mobile?.type === 'max' && <div  className="error" style={{ color:"red" }}> 10 digits allowed </div>}

                    <input type="text" name="city" placeholder="city" className="form-control" data-aos="fade-up" data-aos-once="false" data-aos-delay="300"/>
                                  
                    <textarea name="message" rows="2" cols="50" className="form-control" placeholder="message" data-aos="fade-up" data-aos-once="false" data-aos-delay="400"> 
                    </textarea>
                    {/* <div className="bs-arrow send" >
            <div className="next-arrow"> */}
                    <button type="submit" class="btn bs-arrow send">
                    <div className="next-arrow">
                        <i  class="icon icon-Line"></i>
                    </div>
                        </button>
                        
                    {/* </div>
        </div> */}
                </form>
              
            </div>
            
        <div className="left-sec">
               
               <div>   
                 
                   <p className="book-appointment">
                       <button onClick={onOpenModal} data-aos="fade-up" data-aos-once="false" data-aos-delay="50">Book an appointment</button>
                       <Modal open={open} onClose={onCloseModal} center>
                           <div style={{minWidth:'320px',height:'400px'}}>
                               <h2>Schedule your appointment</h2>
                               <p>Hey there,</p>
                               <p>We look forward to meeting you and getting inspired by your ideas, 
                                   or perhaps inspiring you with some of our own.
                                    Let’s get started by catching up over some coffee 
                                    and a free consultation, on your preferred date and time. </p>
                           </div>

                           <InlineWidget url="https://calendly.com/inquiry_tbs?text_color=080808&primary_color=322d39" />

                           {/* <InlineWidget url="https://calendly.com/inquiry_tbs?text_color=080808&primary_color=322d39" /> */}
                           {/* <div className="calendly-inline-widget" data-url="https://calendly.com/inquiry_tbs?text_color=080808&primary_color=322d39"
                           
                           ></div> */}
                           
                       </Modal>
                       
                   </p>
                   <div className="address-info">
                       <p className="bs-para typ-normal typ-address text-left" data-aos="fade-up" data-aos-once="false" data-aos-delay="100">
                           Address : Raj Palace, lane 11, Bhandarkar Rd, <br/>Shreeman Society, Deccan Gymkhana, Pune,<br/> Maharashtra 411004
                       </p>
                       <h6 className=" text-left" data-aos="fade-up" data-aos-once="false" data-aos-delay="200"><a href="https://www.google.com/maps/dir//the+bath+studio,+Raj+Palace,+lane+11,+Bhandarkar+Rd,+Shreeman+Society,+Deccan+Gymkhana,+Pune,+Maharashtra+411004/@18.5183096,73.7993904,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2bfc9177a8667:0xd24a842abfbf5d7c!2m2!1d73.8344101!2d18.5182314" target="_blank"><span><i className="icon icon-location"></i></span>GET DIRECTIONS</a></h6>
   
                       <h6 className=" text-left" data-aos="fade-up" data-aos-once="false" data-aos-delay="300"><a href="https://wa.me/+919920905368"><span><i className="icon whatsappicon icon-WhatsappLogo-1"></i></span>+91-9920905368</a></h6>
   
                       <h6 className=" text-left" data-aos="fade-up" data-aos-once="false" data-aos-delay="400"><a href="mailto:inquiry@thebathstudio.in"><span><i className="icon icon-mail"></i></span>inquiry@thebathstudio.in</a></h6>
                   </div>
               </div>
                    
           </div>
        </div>
        </Container>
    </div>
  );
}

export default Contact;


