import {useEffect} from "react";
import {NavLink} from "react-router-dom";
import { Container, Row, Col,} from 'reactstrap';
//Animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";
import { homePageUrl } from "../module/Constants";
//Images
import brandImage1 from "../images/Brands/brandImage1.png";
import brandImage2 from "../images/Brands/brandImage2.png";
import brandImage3 from "../images/Brands/hansgrohe-logo.svg";
import brandImage4 from "../images/Brands/brandImage4.png";
import brandImage5 from "../images/Brands/brandImage5.png";
import brandImage6 from "../images/Brands/brandImage6.png";
import brandImage7 from "../images/Brands/brandImage7.png";
import brandImage8 from "../images/Brands/brandImage8.png";
import brandImage9 from "../images/Brands/brandImage9.png";
import brandImage10 from "../images/Brands/brandImage10.png";
import brandImage11 from "../images/Brands/brandImage1111.png";
import brandImage12 from "../images/Brands/brandImage12.png";


const Brands = () => {
    useEffect(()=>{
        Aos.init({duration: 1000, disable: 'mobile'});
    });
  return(
    <div className="lyt-section typ-brand">
          <Container>
          <Row>
            <Col lg={2} md={2} sm={12}>
                <div className="bs-heading sm-center"  data-aos="slide-up" data-aos-once="false" data-aos-delay="50">
                    <div className="title typ-sml typ-cps">brands</div>
                </div>
            </Col>
          </Row>
        {/* <div className="bs-heading sm-center">
            <div className="title typ-cps" data-aos="slide-up" data-aos-once="false">brands</div>
        </div> */}
        <div className="bs-grid cm">

            {/* <div data-aos="fade-up" data-aos-once="false" data-aos-delay="100">
                <NavLink exact to="/kolher"  className="logosec"><img src={brandImage1} alt="Brand 1" />                
                </NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                <NavLink exact to="/artist_edition"  className="logosec"><img src={brandImage2} alt="Brand 2"/></NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                <NavLink exact to="/hangsrohe"  className="logosec svg-img"><img src={brandImage3} alt="Brand 3"/></NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                <NavLink exact to="/axor"  className="logosec"><img src={brandImage4} alt="Brand 4"/></NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="500">
                <NavLink exact to="/fenesta"  className="logosec"><img src={brandImage5} alt="Brand 5"/></NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="600">
                <NavLink exact to="/Ritikaawood"  className="logosec"><img src={brandImage6} alt="Brand 6"/></NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="700">
                <NavLink exact to="/vaillant"  className="logosec"><img src={brandImage7} alt="Brand 7"/></NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                <NavLink exact to="/ebara"  className="logosec"><img src={brandImage8} alt="Brand 8"/></NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="900">
                <NavLink exact to="/smith" className="logosec"><img src={brandImage9} alt="Brand 9"/></NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1000">
                <NavLink exact to="/waterfileration" className="logosec"><img src={brandImage10} alt="Brand 10"/></NavLink>
            </div>
            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1100">
                <NavLink exact to="/design_quarry"  className="logosec"><img src={brandImage11} className="decImage"
                alt="Brand 11"/></NavLink>
            </div>
            <div>
                <NavLink exact to="#"  className="logosec"></NavLink>
            </div> */}


            <div className="logo1">
                <NavLink exact to={`${homePageUrl}kolher`} className="logosec"><img src={brandImage1} alt="Brand 1" />                
                </NavLink>
            </div>
            <div className="logo2">
                <NavLink exact to={`${homePageUrl}artist_edition`} className="logosec"><img src={brandImage2} alt="Brand 2"/></NavLink>
            </div>
            <div className="logo3">
                <NavLink exact to={`${homePageUrl}hangsrohe`} className="logosec svg-img"><img src={brandImage3} alt="Brand 3"/></NavLink>
            </div>
            <div className="logo4">
                <NavLink exact to={`${homePageUrl}axor`} className="logosec"><img src={brandImage4} alt="Brand 4"/></NavLink>
            </div>
            <div className="logo5">
                <NavLink exact to={`${homePageUrl}fenesta`} className="logosec"><img src={brandImage5} alt="Brand 5"/></NavLink>
            </div>
            <div className="logo6">
                <NavLink exact to={`${homePageUrl}ritikaawood`} className="logosec"><img src={brandImage6} alt="Brand 6"/></NavLink>
            </div>
            <div className="logo7">
                <NavLink exact to={`${homePageUrl}vaillant`} className="logosec"><img src={brandImage7} alt="Brand 7"/></NavLink>
            </div>
            <div className="logo8">
                <NavLink exact to={`${homePageUrl}ebara`} className="logosec"><img src={brandImage8} alt="Brand 8"/></NavLink>
            </div>
            <div className="logo9">
                <NavLink exact to={`${homePageUrl}smith`} className="logosec"><img src={brandImage9} alt="Brand 9"/></NavLink>
            </div>
            <div className="logo10">
                <NavLink exact to={`${homePageUrl}waterfileration`} className="logosec"><img src={brandImage10} alt="Brand 10"/></NavLink>
            </div>
            <div className="logo11">
                <NavLink exact to={`${homePageUrl}design_quarry`} className="logosec"><img src={brandImage11} className="decImage"
                alt="Brand 11"/></NavLink>
            </div>
            <div className="logo12">
                <NavLink exact to={`${homePageUrl}zanskar`} className="logosec"><img src={brandImage12} className="zanskar"
                alt="Brand 12"/></NavLink>
            </div>
            {/* <div>
                <NavLink exact to="#"  className="logosec"></NavLink>
            </div> */}


            {/* <div className="logo1">
                <NavLink exact to="/clients/tbs-website/kolher" className="logosec"><img src={brandImage1} alt="Brand 1"/></NavLink>
            </div>
            <div className="logo2">
                <NavLink exact to="/clients/tbs-website/artist_edition" className="logosec"><img src={brandImage2} alt="Brand 2"/></NavLink>
            </div>
            <div className="logo3">
                <NavLink exact to="/clients/tbs-website/hangsrohe" className="logosec svg-img"><img src={brandImage3} alt="Brand 3"/></NavLink>
            </div>
            <div className="logo4">
                <NavLink exact to="/clients/tbs-website/axor" className="logosec"><img src={brandImage4} alt="Brand 4"/></NavLink>
            </div>
            <div className="logo5">
                <NavLink exact to="/clients/tbs-website/fenesta" className="logosec"><img src={brandImage5} alt="Brand 5"/></NavLink>
            </div>
            <div className="logo6">
                <NavLink exact to="/clients/tbs-website/Ritikaawood" className="logosec"><img src={brandImage6} alt="Brand 6"/></NavLink>
            </div>
            <div className="logo7">
                <NavLink exact to="/clients/tbs-website/vaillant" className="logosec"><img src={brandImage7} alt="Brand 7"/></NavLink>
            </div>
            <div className="logo8">
                <NavLink exact to="/clients/tbs-website/ebara" className="logosec"><img src={brandImage8} alt="Brand 8"/></NavLink>
            </div>
            <div className="logo9">
                <NavLink exact to="/clients/tbs-website/smith" className="logosec"><img src={brandImage9} alt="Brand 9"/></NavLink>
            </div>
            <div className="logo10">
                <NavLink exact to="/clients/tbs-website/waterfileration" className="logosec"><img src={brandImage10} alt="Brand 10"/></NavLink>
            </div>
            <div className="logo11">
                <NavLink exact to="/clients/tbs-website/design_quarry" className="logosec"><img src={brandImage11} className="decImage"
                alt="Brand 11"/></NavLink>
            </div> 
               <div>
                 <NavLink exact to="/clients/tbs-website" className="logosec"></NavLink>
             </div> */}
            
           

         
            

        </div>
        </Container>
    </div>
  );
}

  
export default Brands;

