import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { Container, Row, Col } from 'reactstrap';

import { homePageUrl } from '../module/Constants.js';
import Aos from "aos";
import "aos/dist/aos.css";
import { SRLWrapper } from "simple-react-lightbox";

//Images
import backArrow from "../images/backArrow.png";
import Surface1 from "../images/Products/Surface1.jpg";
import Surface2 from "../images/Products/Surface2.jpg";
import Surface3 from "../images/Products/Surface3.jpg";
import Surface4 from "../images/Products/Surface4.jpg";
import Surface5 from "../images/Products/Surface5.jpg";
import Surface6 from "../images/Products/Surface6.jpg";
import Surface7 from "../images/Products/Surface7.jpg";
import Surface8 from "../images/Products/Surface8.jpg";
import Surface9 from "../images/Products/Surface9.jpg";
import Surface10 from "../images/Products/Surface10.jpg";

// large

import Surface_Large1 from "../images/Products/large/Surface1.jpg";
import Surface_Large2 from "../images/Products/large/Surface2.jpg";
import Surface_Large3 from "../images/Products/large/Surface3.jpg";
import Surface_Large4 from "../images/Products/large/Surface4.jpg";
import Surface_Large5 from "../images/Products/large/Surface5.jpeg";
import Surface_Large6 from "../images/Products/large/Surface6.jpg";
import Surface_Large7 from "../images/Products/large/Surface7.jpg";
import Surface_Large8 from "../images/Products/large/Surface8.jpg";
import Surface_Large9 from "../images/Products/large/Surface9.jpg";
import Surface_Large10 from "../images/Products/large/Surface10.jpg";

const Surfaces = () => {

    useEffect(() => {
        Aos.init({ duration: 2000, delay: 2 });
    });

    return (
        <div className="lyt-section">
            
            <Container>
                <div className="bs-hero">
                    <Row>
                        <Col>
                            <div className="bs-arrow back">
                                {/* <NavLink exact to="/clients/tbs-website/products"><i className="icon icon-Line"></i></NavLink> */}
                                <NavLink exact to={`${homePageUrl}products`}><i className="icon icon-Line"></i></NavLink>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} >
                <div className="heading">
                    <span className="title">Surfaces<sup>10</sup></span>
                </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                <div className="description">
                    <div>
                    <h3 className="desc-title">Staying on the surface.</h3>
                    </div>
                    <div>
                        <p>
                        Think seamless. Think waterproof. Think stain resistant. Think micro cement that isn’t just easy to care for, but also easy on the eye. Truly, the perfect complement to elegantly designed bathrooms, kitchens and living areas. 
                        </p>
                    </div>
                    <div className="feature-details">
                        <div>
                            <span className="specification">Featured Brands:&nbsp;</span>

                            <span className="spec-detail1">
                                <NavLink exact to={`${homePageUrl}design_quarry`} className="detail-link">Design Quarry</NavLink>
                                {/* <NavLink exact to="/clients/tbs-website/design_quarry" className="detail-link">Design Quarry</NavLink> */}

                            </span>
                        </div>
                    </div>
                    <div>
                        <div class="feature-details">
                            <span className="specification">Materials:&nbsp;</span>
                            <span className="spec-detail2">  Micro cement </span>
                        </div>
                    </div>
                    
                   

                </div>
                </Col>
                    </Row>
            </div>
            <SRLWrapper>
                <div className="bs-masonry">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 992: 3 }} >
                        <Masonry gutter="20px">
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                                <a href={Surface_Large1}>
                                    <img src={Surface1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                            <a href={Surface_Large2}>
                                    <img src={Surface2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                            <a href={Surface_Large3}>
                                    <img src={Surface3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="500">
                            <a href={Surface_Large4}>
                                    <img src={Surface4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="600">
                            <a href={Surface_Large5}>
                                    <img src={Surface5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="700">
                            <a href={Surface_Large6}>
                                    <img src={Surface6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                            <a href={Surface_Large8}>
                                    <img src={Surface8} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                            <a href={Surface_Large10}>
                                    <img src={Surface10} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="900">
                            <a href={Surface_Large7}>
                                    <img src={Surface7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </SRLWrapper>
            </Container>
        </div>
    );
}

export default Surfaces;