import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Container, Row, Col } from 'reactstrap';

import { SRLWrapper } from "simple-react-lightbox";

import { homePageUrl } from '../module/Constants.js';

//Images
import backArrow from "../images/backArrow.png";
import windowImage1 from "../images/Projects/project-1_img-1.jpg";
import windowImage2 from "../images/Projects/project-1_img-2.jpg";
import windowImage3 from "../images/Projects/project-1_img-3.jpg";
import windowImage4 from "../images/Projects/project-1_img-4.jpg";

// large

import windowImage_Large1 from "../images/Projects/large/project-1_img-1.jpg";
import windowImage_Large2 from "../images/Projects/large/project-1_img-2.jpg";
import windowImage_Large3 from "../images/Projects/large/project-1_img-3.jpg";
import windowImage_Large4 from "../images/Projects/large/project-1_img-4.jpg";

//Animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";


const Architect_dhruva_samal_bathrooms_projects = () => {

    useEffect(()=>{
        Aos.init({duration: 1500, disable: 'mobile',delay: 1});
      })

    return (
        <div className="lyt-section">
             
             
            <Container>
            <div className="bs-hero">
                <Row>
                    <Col>
                        <div class="bs-arrow back">
                            {/* <a href="/projects"><i class="icon icon-Line"></i></a> */}
                            <NavLink exact to={`${homePageUrl}projects`}><i className="icon icon-Line"></i></NavLink>

                            {/* <NavLink exact to="/clients/tbs-website/projects"><i class="icon icon-Line"></i></NavLink> */}
                        </div>
                    </Col>
                </Row>
            <Row>
                {/* <Col lg={6} md={6} sm={12}> */}
                <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} >
                <div className="heading typ-detail-heading">
                    <span className="title"> Residential project, Mumbai/Pawna </span>
                </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                <div className="description">
                    {/* <div>
                        <p className="black">An address that embodies the global standards in luxury, Lodha World towers
                            rises majestically above the city’s skyline featuring the city’s most stunning
                            apartments. One of those happen to highlight a collaboration between TBS and
                            nationally renowned architect Rahul Sharma - as seen here.
                        </p>
                    </div> */}
                    <div className="bs-project feature">
                        <div className="bs-heading">
                            <p className="title typ-cps black">architect</p>
                            <p className="title typ-cps black">Location </p>
                            <p className="title typ-cps black">Classification </p>
                            <p className="title typ-cps black">Featured brands</p>
                            <p className="title typ-cps black">Product showcase</p>
                        </div>
                        <div className="bs-heading">
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Dhruva Samal</p>
                            </div>
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Mumbai/Pawna</p>
                            </div>
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Home/Residential</p>
                            </div>
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Kohler and Vaillant </p>
                            </div>
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Bathrooms and hot water systems</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </Col>
            </Row>
            </div>
            <SRLWrapper>
                <div className="bs-masonry">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 992: 3 }} >
                        <Masonry gutter="20px">
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="100">
                                <a href={windowImage_Large1}>
                                    <img src={windowImage1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <img src={windowImage1} alt="Bathroom 1" /> */}
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                            <a href={windowImage_Large2}>
                                    <img src={windowImage2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <img src={windowImage2} alt="Bathroom 2" /> */}
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                            <a href={windowImage_Large3}>
                                    <img src={windowImage3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                            <a href={windowImage_Large4}>
                                    <img src={windowImage4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            {/* <div>
                            <img src={windowImage1} alt="Bathroom 5"/>
                            <p>UPVC sliding windows lending a contemporary touch.</p>
                        </div>
                        <div>
                            <img src={windowImage2} alt="Bathroom 6"/>
                            <p>Double hung aluminium windows with a classic appearance.</p>
                        </div> */}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </SRLWrapper>
            </Container>
            
        </div>
    )
}

export default Architect_dhruva_samal_bathrooms_projects;