import {NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import { Container,Containerfluid, Row, Col } from 'reactstrap';

import { useHistory } from "react-router-dom";
// import Swiper core and required modules
import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper/core';

//Swiper styles
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";

//Images
import backArrow from "../images/backArrow.png";
import brandImage2 from "../images/Brands/brandImage2.png";
import bd2Img1 from "../images/Brands/bd2_img1.jfif";
import bd2Img2 from "../images/Brands/bd2_img2.jpg";
import bd2Img3 from "../images/Brands/bd2_img3.jfif";

import KohlerAeCatelogue from "../pdf/kohler-ae.pdf";

import brandDownload from "../images/brandDownload.png";
  // install Swiper modules
  SwiperCore.use([Navigation,Pagination]);

const Artist_edition = (props) =>{
    let history = useHistory();
    return(
        <div className="">
        <Container fluid className="brand-detl">
         

        <div className="bs-brand-detail">
        <Row className="brand-img-detl">
            <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
            <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                <i className="icon icon-Line"></i>                        
        </div>
                <div className="content">
                        <img src={brandImage2} alt="Brand Image 1"/>
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">
                        The Artist Editions line of decorative products is among the signature lines of the Kohler Co., which was founded in 1873 by John Michael Kohler.The range, best described as functional works of art, go beyond just breathtaking design. Handcrafted from the finest materials to be one of a kind, these products have a striking individuality, quite like those that choose them.</p>

                        <p className="bs-para typ-normal para2">
                        Balancing artistry and craftsmanship with utility, Artist Editions products invite you to celebrate beauty and creativity each day. The range is today, one of the favourites across the globe, and across Kohler showrooms in India. 
                        </p>
                        <div className="bs-heading ">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal para3">
                        
TBS has been associated with KOHLER for their bathrooms business since 2008, while enjoying the distinction of being the first appointed dealer for the brand in Pune. And just as importantly, is also the only dealer of the brand’s Artist Editions’ range of products, in Maharashtra (outside Mumbai). 

                        </p>
                        {/* <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal">
                        TBS has been associated with KOHLER for their bathrooms business since 2008,
                        while enjoying the distinction of being the first appointed dealer
                        for the brand in Pune. And just as importantly, is also the only dealer
                        of the brand’s Artist Editions’ range of products, in Maharashtra(outside Mumbai).
                        </p> */}
                        
                        <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                           
                                <a href={KohlerAeCatelogue} download="KohlerAeCatelogue" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Kohler&nbsp;Artist&nbsp;Edition&nbsp;Master&nbsp;Catalogue</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        {/* <div className="catalogue-desc bs-para">
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Kohler&nbsp;Vibrant&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        <div className="catalogue-desc bs-para">
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                    </div>
                    </Col>  

                    <Col lg={6} md={12} sm={12}   >
                        <div className="image">
                        <iframe src="https://youtube.com/embed/iWPZjHJRm6c" title="description" width="100%" height="100%"></iframe>
                    </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Artist_edition;