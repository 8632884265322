import React, { useRef , useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { SRLWrapper } from "simple-react-lightbox";

import { Container, Row, Col,} from 'reactstrap';

import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper/core';

//Swiper styles
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";

//images
import nextArrow from "../images/nextArrow.png";

import gallery1Img1 from "../images/Experience/gallery1Img1.jpg";
import gallery1Img2 from "../images/Experience/gallery1Img2.jpg";
import gallery1Img3 from "../images/Experience/gallery1Img3.jpg";
import gallery1Img4 from "../images/Experience/gallery1Img4.jpg";
import gallery1Img5 from "../images/Experience/gallery1Img5.jpg";
import gallery1Img6 from "../images/Experience/gallery1Img6.jpg";
import gallery1Img7 from "../images/Experience/gallery1Img7.jpg";
import gallery1Img8 from "../images/Experience/gallery1Img8.jpg";
import gallery1Img9 from "../images/Experience/gallery1Img9.jpg";
import gallery1Img10 from "../images/Experience/gallery1Img10.jpg";
import gallery1Img11 from "../images/Experience/gallery1Img11.jpg";
import gallery1Img12 from "../images/Experience/gallery1Img12.jpg";
// import gallery1Img13 from "../images/Experience/gallery1Img13.jpg";
// import galleryImg10 from "../images/Experience/img10.jpg";
import galleryImg11 from "../images/Experience/img11.jpg";
import galleryImg12 from "../images/Experience/img12.jpg";
import galleryImg13 from "../images/Experience/img13.jpg";
// import galleryImg14 from "../images/Experience/img14.jpg";

import gallery2Img1 from "../images/Experience/gallery2Img1.JPG";
import gallery2Img2 from "../images/Experience/gallery2Img2.JPG";
import gallery2Img3 from "../images/Experience/gallery2Img3.JPG";
import gallery2Img4 from "../images/Experience/gallery2Img4.JPG";
import gallery2Img5 from "../images/Experience/gallery2Img5.jpg";
import gallery2Img6 from "../images/Experience/gallery2Img6.JPG";
import gallery2Img7 from "../images/Experience/gallery2Img7.jpeg";
import gallery2Img8 from "../images/Experience/gallery2Img8.jpeg";
import gallery2Img9 from "../images/Experience/gallery2Img9.jpeg";
import gallery2Img10 from "../images/Experience/gallery2Img10.jpg";
import gallery2Img11 from "../images/Experience/gallery2Img11.jpg";
// import gallery2Img12 from "../images/Experience/gallery2Img12.jpg";
import gallery2Img13 from "../images/Experience/gallery2Img13.jpg";
import gallery2Img14 from "../images/Experience/gallery2Img14.jpg";
import gallery2Img15 from "../images/Experience/gallery2Img15.jpg";
import gallery2Img16 from "../images/Experience/gallery2Img16.jpg";
import gallery2Img17 from "../images/Experience/gallery2Img17.jpg";
import gallery2Img18 from "../images/Experience/gallery2Img18.jpg";
import gallery2Img19 from "../images/Experience/gallery2Img19.jpg";
import gallery2Img20 from "../images/Experience/gallery2Img20.jpg";
import gallery2Img21 from "../images/Experience/gallery2Img21.jpg";
import gallery2Img22 from "../images/Experience/gallery2Img22.jpg";



// large

import gallery1ImgLarge1 from "../images/Experience/large/floor1/img1.jpg";
import gallery1ImgLarge2 from "../images/Experience/large/floor1/img2.jpg";
import gallery1ImgLarge3 from "../images/Experience/large/floor1/img3.jpg";
import gallery1ImgLarge4 from "../images/Experience/large/floor1/img4.jpg";
import gallery1ImgLarge5 from "../images/Experience/large/floor1/img5.jpg";
import gallery1ImgLarge6 from "../images/Experience/large/floor1/img6.jpg";
import gallery1ImgLarge7 from "../images/Experience/large/floor1/img7.jpg";
import gallery1ImgLarge8 from "../images/Experience/large/floor1/img8.jpg";
import gallery1ImgLarge9 from "../images/Experience/large/floor1/img9.jpg";
import gallery1ImgLarge10 from "../images/Experience/large/floor1/img10.jpg";
import gallery1ImgLarge11 from "../images/Experience/large/floor1/img11.jpg";
import gallery1ImgLarge12 from "../images/Experience/large/floor1/img12.jpg";
import gallery1ImgLarge13 from "../images/Experience/large/floor1/img13.jpg";
// import galleryImgLarge10 from "../images/Experience/large/floor1/galimg10.jpg";
import galleryImgLarge11 from "../images/Experience/large/floor1/galimg11.jpg";
import galleryImgLarge12 from "../images/Experience/large/floor1/galimg12.jpg";
import galleryImgLarge13 from "../images/Experience/large/floor1/galimg13.jpg";
import galleryImgLarge14 from "../images/Experience/large/floor1/galimg14.jpg";

import gallery2ImgLarge1 from "../images/Experience/large/floor2/img1.jpg";
import gallery2ImgLarge2 from "../images/Experience/large/floor2/img2.jpg";
import gallery2ImgLarge3 from "../images/Experience/large/floor2/img3.jpg";
import gallery2ImgLarge4 from "../images/Experience/large/floor2/img4.jpg";
import gallery2ImgLarge5 from "../images/Experience/large/floor2/img5.jpg";
import gallery2ImgLarge6 from "../images/Experience/large/floor2/img6.jpg";
import gallery2ImgLarge7 from "../images/Experience/large/floor2/img7.jpg";
import gallery2ImgLarge8 from "../images/Experience/large/floor2/img8.jpg";
import gallery2ImgLarge9 from "../images/Experience/large/floor2/img9.jpg";
// import gallery2ImgLarge10 from "../images/Experience/large/floor2/img10.jpg";
import gallery2ImgLarge11 from "../images/Experience/large/floor2/img11.jpg";
// import gallery2ImgLarge12 from "../images/Experience/large/floor2/img12.jpg";
import gallery2ImgLarge13 from "../images/Experience/large/floor2/img13.jpg";
import gallery2ImgLarge14 from "../images/Experience/large/floor2/img14.jpg";
import gallery2ImgLarge15 from "../images/Experience/large/floor2/img15.jpg";
import gallery2ImgLarge16 from "../images/Experience/large/floor2/img16.jpg";
import gallery2ImgLarge17 from "../images/Experience/large/floor2/img17.jpg";
import gallery2ImgLarge18 from "../images/Experience/large/floor2/img18.jpg";
import gallery2ImgLarge19 from "../images/Experience/large/floor2/img19.jpg";
import gallery2ImgLarge20 from "../images/Experience/large/floor2/img20.jpg";
import gallery2ImgLarge21 from "../images/Experience/large/floor2/img21.jpg";


//Animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";


// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const Experience = () => {

    const ref = useRef(null);
    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    };
    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    };

    useEffect(()=>{
        Aos.init({duration: 2000, disable: 'mobile',delay: 1});
      })

    return (
        <div className="lyt-section bs-project typ-exp">
            <Container className="exp-section">
          
            <div className="content cm mtop-15">
                {/* <div className="bs-heading">
                    <div className="title typ-cps">GALLERY</div>
                </div> */}
                <Row>
            <Col>
                <div className="bs-heading sm-center"  data-aos="slide-up" data-aos-once="false" data-aos-delay="50">
                    <div className="title typ-sml typ-cps">GALLERY</div>
                </div>
            </Col>
          </Row>
                <div className="bs-para typ-normal exp-info">

                    {/* <p data-aos="slide-up" data-aos-once="false">
                        Sprawling across 3000 sq.ft and two floor levels,
                        the expansive TBS showroom evokes all things modern and majestic
                        - through its one-of-a-kind experiential showcase of global brands.
                        Inspired by art, minimalism and design, it’s a silent retreat for
                        the senses of those seeking function, craftsmanship and those little
                        moments of inspiration. </p> */}
                       
                       <p data-aos="fade-up" data-aos-once="false"  data-aos-delay="200" >Sprawling across 3000 sq.ft and two floor levels, the expansive TBS showroom evokes all things modern and majestic - through its one-of-a-kind experiential showcase of global brands. Inspired by art, minimalism and design, it’s a silent retreat for the senses of those seeking function, craftsmanship and those little moments of inspiration. </p>
                </div>  
               
                
            </div>
               
            
            <div className="">               
                <SRLWrapper>
                    <Swiper ref={ref} loop={true} navigation={true} autoHeight={true}>
                        <SwiperSlide>
                            <div className="bs-masonry typ-exp">
                                <div className="exp-heading">
                                    <div className="title typ-cps">Level 1</div>
                                </div> 
                                <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 350: 2, 767: 3, 992: 5 }} >
                                    <Masonry>
                                        <div>
                                            <a href={gallery1ImgLarge1}>
                                                <img src={gallery1Img1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={gallery1ImgLarge2}>
                                                <img src={gallery1Img2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={gallery1ImgLarge3}>
                                                <img src={gallery1Img3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={gallery1ImgLarge4}>
                                                <img src={gallery1Img4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={gallery1ImgLarge5}>
                                                <img src={gallery1Img5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={gallery1ImgLarge6}>
                                                <img src={gallery1Img6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={gallery1ImgLarge7}>
                                                <img src={gallery1Img7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={gallery1ImgLarge8}>
                                                <img src={gallery1Img8} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={gallery1ImgLarge9}>
                                                <img src={gallery1Img9} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>

                                        <div>
                                            <a href={gallery1ImgLarge12}>
                                                <img src={gallery1Img12} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        
                                        <div>
                                            <a href={gallery1ImgLarge10}>
                                                <img src={gallery1Img10} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={gallery1ImgLarge11}>
                                                <img src={gallery1Img11} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        
                                        {/* <div>
                                            <a href={galleryImgLarge10}>
                                                <img src={galleryImg10} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div> */}
                                        <div>
                                            <a href={galleryImgLarge11}>
                                                <img src={galleryImg11} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={galleryImgLarge12}>
                                                <img src={galleryImg12} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href={galleryImgLarge13}>
                                                <img src={galleryImg13} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        {/* <div>
                                            <a href={galleryImgLarge14}>
                                                <img src={galleryImg14} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div> */}
                                        {/* <div>
                                            <a href={gallery1ImgLarge13}>
                                                <img src={gallery1Img13} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div> */}
                                    </Masonry>
                                </ResponsiveMasonry>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bs-masonry typ-exp typ-l2">
                            <div className="exp-heading">
                    <div className="title typ-cps">Level 2</div>
                </div> 
                                <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 350: 2, 767: 3, 992: 5 }} >
                                    <Masonry>
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge1}>
                                                <img src={gallery2Img1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                        <a href={gallery2ImgLarge2}>
                                                <img src={gallery2Img2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                        <a href={gallery2ImgLarge3}>
                                                <img src={gallery2Img3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                        <a href={gallery2ImgLarge4}>
                                                <img src={gallery2Img4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                        <a href={gallery2ImgLarge5}>
                                                <img src={gallery2Img5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                        <a href={gallery2ImgLarge6}>
                                                <img src={gallery2Img6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                        <a href={gallery2ImgLarge7}>
                                                <img src={gallery2Img7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                        <a href={gallery2ImgLarge8}>
                                                <img src={gallery2Img8} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge9}>
                                                <img src={gallery2Img9} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>

                                        {/* <div className="gal-img">
                                            <a href={gallery2ImgLarge10}>
                                                <img src={gallery2Img10} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div> */}

                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge11}>
                                                <img src={gallery2Img11} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>

                                        {/* <div className="gal-img">
                                            <a href={gallery2ImgLarge12}>
                                                <img src={gallery2Img12} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div> */}

                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge13}>
                                                <img src={gallery2Img13} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge14}>
                                                <img src={gallery2Img14} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge15}>
                                                <img src={gallery2Img15} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge16}>
                                                <img src={gallery2Img16} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge17}>
                                                <img src={gallery2Img17} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge18}>
                                                <img src={gallery2Img18} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge19}>
                                                <img src={gallery2Img19} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge20}>
                                                <img src={gallery2Img20} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                        {/* <div className="gal-img">
                                            <a href={gallery2ImgLarge21}>
                                                <img src={gallery2Img21} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div> */}
                                        <div className="gal-img">
                                            <a href={gallery2ImgLarge7}>
                                                <img src={gallery2Img7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                            </a>
                                        </div>
                                    </Masonry>
                                </ResponsiveMasonry>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </SRLWrapper>
            </div>
            </Container>
        </div>
    );
}

export default Experience;