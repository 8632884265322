import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { Container, Row, Col } from 'reactstrap';

import { homePageUrl } from '../module/Constants.js';

import Aos from "aos";
import "aos/dist/aos.css";
import { SRLWrapper } from "simple-react-lightbox";

//Images
import backArrow from "../images/backArrow.png";
import Facade1 from "../images/Products/Facade1.jpg";
import Facade2 from "../images/Products/Facade2.jpg";
import Facade3 from "../images/Products/Facade3.jpg";
import Facade4 from "../images/Products/Facade4.jpg";
import Facade5 from "../images/Products/Facade5.png";
import Facade6 from "../images/Products/Facade6.jpg";
import Facade7 from "../images/Products/Facade7.jpg";
import Facade8 from "../images/Products/Facade8.jpg";

// large

import Facade_Large1 from "../images/Products/large/Facade1.jpg";
import Facade_Large2 from "../images/Products/large/Facade2.jpg";
import Facade_Large3 from "../images/Products/large/Facade3.jpg";
import Facade_Large4 from "../images/Products/large/Facade4.jpg";
import Facade_Large5 from "../images/Products/large/Facade5.png";
import Facade_Large6 from "../images/Products/large/Facade6.jpg";
import Facade_Large7 from "../images/Products/large/Facade7.jpg";
import Facade_Large8 from "../images/Products/large/Facade8.jpg";

const Facades = () => {

    useEffect(() => {
        Aos.init({ duration: 2000, delay: 2 });
    });

    return (
        <div className="lyt-section">
           
            <Container>
                <div className="bs-hero">
                    <Row>
                        <Col>
                        <div className="bs-arrow back">
                            {/* <NavLink exact to="/clients/tbs-website/products"><i className="icon icon-Line"></i></NavLink> */}
                            <NavLink exact to={`${homePageUrl}products`} ><i className="icon icon-Line"></i></NavLink>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} >
                <div className="heading">
                    <span className="title">Facades<sup>08</sup></span>
                </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                <div className="description">
                    <div>
                    <h3 className="desc-title">It’s hard to look beyond the facade. </h3>
                    </div>
                    <div>
                        <p>
                        When it comes to cutting edge facade design, we go the extra mile. Claddings. Pergolas. Sliding screens. There is perhaps no better way to deal with changing environmental conditions like the wind, rain and sun. All, while improving light control and injecting a seamless, ultramodern aesthetic into any living space.
                        </p>
                    </div>

                    <div className="feature-details">
                        <div>
                            <span className="specification">Featured Brands:&nbsp;</span>
                            <span className="spec-detail1">
                                <NavLink exact to={`${homePageUrl}ritikaawood`} className="detail-link">Ritikaawood</NavLink>
                                {/* <NavLink exact to="/clients/tbs-website/ritikaawood" className="detail-link">Ritikaawood</NavLink> */}
                            </span>
                        </div>
                    </div>
                    

                    <div>
                        <div class="feature-details">
                            <span className="specification">Materials:&nbsp;</span>
                            <span className="spec-detail2">  Wood </span>
                        </div>
                    </div>

                    


                </div>
                </Col>
                    </Row>
            </div>
            <SRLWrapper>
                <div className="bs-masonry">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 992: 3 }} >
                        <Masonry gutter="20px">
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                                <a href={Facade_Large1}>
                                    <img src={Facade1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                                <a href={Facade_Large2}>
                                    <img src={Facade2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                                <a href={Facade_Large3}>
                                    <img src={Facade3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="500">
                                <a href={Facade_Large4}>
                                    <img src={Facade4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="600">
                                <a href={Facade_Large5}>
                                    <img src={Facade5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                                <a href={Facade_Large6}>
                                    <img src={Facade6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                                <a href={Facade_Large7}>
                                    <img src={Facade7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                           
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </SRLWrapper>
            </Container>
        </div>
    );
}

export default Facades;