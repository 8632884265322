import React, { useState} from 'react';
import {NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

import { Container,Containerfluid, Row, Col } from 'reactstrap';

//Images
import backArrow from "../images/backArrow.png";
import brandImage1 from "../images/Brands/brandImage1.png";
import branddetailsImage1 from "../images/Brands/branddetailsImage1.png";
import brandDownload from "../images/Brands/brandDownload.png";

// import OurWorld from "../video/OurWorld.mp4";

import KohlerCatalogue from "../pdf/kohler.pdf";
// import { Button } from 'react-scroll';

const Kolher = (props) =>{
    let history = useHistory();
    //Cursor Animation
    const [MousePosition, setMousePosition] = useState({
        left: 0,
        top: 0,
    });
    function handleMouseMove(ev) { 
        setMousePosition({left: ev.clientX, top: ev.clientY}); 
        //console.log(ev.clientX,ev.clientY);
        //console.log('Mouse Over Working');
    }
    return(
            <div className="">
                <Container fluid className="brand-detl">
                 

                <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                    <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                        <i className="icon icon-Line"></i>                        
                </div>
                        <div className="content">
                            <img src={brandImage1} alt="Brand Image 1"/>
                            <div className="bs-heading" style={{marginTop:'20px'}}>
                                <h2 className="title typ-cps black">about the brand</h2>    
                            </div>
                            <p className="bs-para typ-normal para1">
                            Kohler Co., was founded in 1873 by John Michael Kohler, 
                            and is an American manufacturing company based in Kohler, 
                            Wisconsin. All about gracious living, KOHLER is best known 
                            for its line of plumbing products, while also manufacturing furniture, 
                            cabinetry, tile, engines, and generators.  
                            </p>

                            <p className="bs-para typ-normal para2">
                            Over the years 
                            KOHLER has created a presence across six continents, 
                            with operating plants in 49 worldwide locations. 
                            The brand entered India in May 2006, and has today expanded 
                            its footprint to over 100+ cities with a network of over 300 showrooms. 
                            </p>

                            <div className="bs-heading">
                                <h2 className="title typ-cps black">About the association </h2>
                            </div>
                            <p className="bs-para typ-normal para3">
                            TBS has been associated with KOHLER for their bathrooms business since 2008, while enjoying the distinction of being the first appointed dealer for the brand in Pune. And just as importantly, is also the only dealer of the brand’s Artist Editions’ range of products, in Maharashtra (outside Mumbai).
                            </p>
                            
                            <div className="bs-heading">
                                <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                            </div>
                            <div className="catalogue-desc bs-para">
                                
                                <a href={KohlerCatalogue} download= "KohlerCatalogue" target='_blank'>
                                    <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                    Kohler&nbsp;Master&nbsp;Catalogue</p>
                                        {/* <i className="dwnload icon-download"></i> */}
                                        <i className="dwnload icon-download"></i>
                                </a>
                            </div>
                            {/* <div className="catalogue-desc bs-para">
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                    Kohler&nbsp;Vibrant&nbsp;Brochure</p>
                                    <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                        <i className="dwnload icon-download"></i>
                                    </a>
                            </div>
                            <div className="catalogue-desc bs-para">
                                <p className="bs-para list">
                                    Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                    <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                        <i className="dwnload icon-download"></i>
                                    </a>
                            </div> */}
                        </div>
                    </Col>  

                    <Col lg={6} md={12} sm={12}   >
                        <div className="image">
                            {/* <video controls onMouseMove={(ev)=> handleMouseMove(ev)}
                            style={{left:MousePosition.left , top: MousePosition.top,width:'100%', height:'100%'}}>
                                <source src={OurWorld} type="video/mp4" />
                            </video> */}
                                                    
                            <iframe src="https://youtube.com/embed/A4_yTiI9ouc" title="description" width="100%" height="100%"></iframe>
                    
                        </div>
                    </Col>
                </Row>
                </div>
                {/* <div className="bs-cursor">
                    <div className="cursor" onMouseMove={(ev)=> handleMouseMove(ev)}
                    style={{left:MousePosition.left , top: MousePosition.top}}></div>
                    <div className="cursor2" onMouseMove={(ev)=> handleMouseMove(ev)}
                    style={{left:MousePosition.left , top: MousePosition.top}}>PLAY</div>
                </div> */}
                </Container>
            </div>  
    )
} 

export default Kolher;