import {NavLink } from "react-router-dom";

import { Container,Containerfluid, Row, Col } from 'reactstrap';

import { useHistory } from "react-router-dom";
//Images
import backArrow from "../images/backArrow.png";
import brandImage10 from "../images/Brands/brandImage10.png";

import brandDownload from "../images/Brands/brandDownload.png";

import ThreeMCatelogue from "../pdf/3M.pdf";


const Waterfileration = (props) =>{
    let history = useHistory();
    return(
            <div className="">
                   <Container fluid className="brand-detl">
        <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                        <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                            <i className="icon icon-Line"></i>                        
                    </div>
                        <div className="content">
                        <img src={brandImage10} alt="Brand Image 1"/>
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">
                        Created in 1902, the company that had humble beginnings is now a highly diversified Fortune 500 giant with operations in 63 countries and product sales in more than 200 countries.</p>

                        <p className="bs-para typ-normal para2">
                        Its expansive portfolio features everything from automotive parts and dental products to home office supplies and surgical solutions, among others. 
                        </p>
                        
                        <p className="bs-para typ-normal para3">
                        The company’s Indian venture however was established in 1987 and has completed more than 25 years of operations. 3M lndia leverages its global innovation expertise to also develop Water Filtration Systems that work at the entry point of water into homes and thereby inhibit problems that arise due to hard water.  At present, 3M India has manufacturing facilities in Bengaluru, Ahmedabad and Pune. 
                        </p>

                        <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal">
                        TBS celebrates its exclusive partnership with 3M Home Water Solutions via its sister concern Aquagreen, since 2016. And also happens to be the single largest customer for 3M home filtration in the west region.
                        </p>
                        
                        <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                           
                                <a href={ThreeMCatelogue} download="ThreeMCatelogue" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                3M&nbsp;Master&nbsp;Catalogue</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        {/* <div className="catalogue-desc bs-para">
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Kohler&nbsp;Vibrant&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        <div className="catalogue-desc bs-para">
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                    </div> 
                    </Col>  

<Col lg={6} md={12} sm={12}>
                    <div className="image">
                        <iframe src="https://youtube.com/embed/dwUqgkEd5us" title="description" width="100%" height="100%"></iframe>
                    </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Waterfileration;