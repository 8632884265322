import {NavLink } from "react-router-dom";


import { Container,Containerfluid, Row, Col } from 'reactstrap';

import { useHistory } from "react-router-dom";
//Images
import backArrow from "../images/backArrow.png";
import brandImage6 from "../images/Brands/brandImage6.png";
import bd6Img1 from "../images/Brands/bd6Img1.jpg";
import bd6Img2 from "../images/Brands/bd6Img2.png";

import brandDownload from "../images/brandDownload.png";

import RitikaawoodCatelogue from "../pdf/ritikaawood-brochure.pdf";


const Ritikaawood = (props) =>{
    let history = useHistory();
    return(
        <div className="">
        <Container fluid className="brand-detl">
        <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                        <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                            <i className="icon icon-Line"></i>                        
                    </div>
                        <div className="content">
                        <img src={brandImage6} alt="Brand Image 1"/>
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">What started off in the construction materials trade business in Aluminium, in the 1980’s transitioned into uPVC materials by 2002 - the year when brand Ritikaa was first formed in India.</p>

                        <p className="bs-para typ-normal para2">
                        However, it was only in July 2011, that RitikaaWood was created - becoming the largest timber engineering company in the country. Today, Ritikaa  offers superior quality and finishes across windows, doors, curtain walls and claddings through a state-of-the-art woodworking facility at Nashik, near Mumbai. The company at present, has successfully delivered over 600 projects across more than 50 cities domestically.
                        </p>

                        <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal para3">
                        TBS has been associated with RitikaaWood since the year 2020, and happens to be the brand’s first appointed dealer for its exclusive range of products - covering the entire Maharashtra region. As the partnership moves to the next level, TBS has even launched a unique, concept showroom showcasing RitikaaWood’s entire range of stunning products.
                        </p>
                        
                        <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                          
                                <a href={RitikaawoodCatelogue} download="RitikaawoodCatelogue" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                            Ritikaawood&nbsp;Master&nbsp;Brochure</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        {/* <div className="catalogue-desc bs-para">
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Kohler&nbsp;Vibrant&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        <div className="catalogue-desc bs-para">
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                    </div>
                    
                    </Col>  

                    <Col lg={6} md={12} sm={12}>
                    <div className="image ritikawoodimg">
                        <img src={bd6Img1} alt="Artist Edition - Kohler1" style={{height:'60%', marginBottom:'10px'}}/>
                        <img src={bd6Img2} alt="Artist Edition - Kohler2" style={{height:'75%', marginBottom:'10px'}}/>
                    </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Ritikaawood;