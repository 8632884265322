import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { Container, Row, Col } from 'reactstrap';
import { homePageUrl } from '../module/Constants.js';
import Aos from "aos";
import "aos/dist/aos.css";

import { SRLWrapper } from "simple-react-lightbox";

//Images
import backArrow from "../images/backArrow.png";
import watersolutions1 from "../images/watersolutions/watersolutions1.jpg";
import watersolutions2 from "../images/watersolutions/watersolutions2.jpg";
import watersolutions3 from "../images/watersolutions/watersolutions3.jpg";
import watersolutions4 from "../images/watersolutions/watersolutions4.jpg";
import watersolutions5 from "../images/watersolutions/watersolutions5.jpg";
import watersolutions6 from "../images/watersolutions/watersolutions6.jpg";
import watersolutions7 from "../images/watersolutions/watersolutions7.jpg";
import watersolutions8 from "../images/watersolutions/watersolutions8.jpg";

// large

import watersolutions_large1 from "../images/watersolutions/large/watersolutions1.jpg";
import watersolutions_large2 from "../images/watersolutions/large/watersolutions2.jpg";
import watersolutions_large3 from "../images/watersolutions/large/watersolutions3.jpg";
import watersolutions_large4 from "../images/watersolutions/large/watersolutions4.jpg";
import watersolutions_large5 from "../images/watersolutions/large/watersolutions5.jpg";
import watersolutions_large6 from "../images/watersolutions/large/watersolutions6.jpg";
import watersolutions_large7 from "../images/watersolutions/large/watersolutions7.jpg";
import watersolutions_large8 from "../images/watersolutions/large/watersolutions8.jpg";

const Surfaces = () => {

    useEffect(() => {
        Aos.init({ duration: 2000, delay: 2 });
    });

    return (
        <div className="lyt-section">
            
            <Container>
                <div className="bs-hero">
                    <Row>
                        <Col>
                            <div className="bs-arrow back">
                                {/* <NavLink exact to="/clients/tbs-website/products"><i className="icon icon-Line"></i></NavLink> */}
                                <NavLink exact to={`${homePageUrl}products`} ><i className="icon icon-Line"></i></NavLink>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} >
                <div className="heading">
                    <span className="title">Water Solutions<sup>08</sup></span>
                </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                <div className="description">
                    <div>
                    <h3 className="desc-title">Flow with luxury.</h3>
                    </div>
                    <div>
                        <p>
                        Enhancing bathroom luxury is often down to not just striking design, but also efficient functionality. Our range of water solutions combine state-of-the-art equipment and cutting edge technology to deliver greater comfort, convenience, sustainability and safety. It may well be time to reimagine the possibilities of a holistic bath experience when transformative technology is used for water heating, filtration and pressure.
                        </p>
                    </div>
                    <div className="feature-details">
                        <div>
                            <span className="specification">Featured Brands:&nbsp;</span>

                            <span className="spec-detail1">

                            <NavLink exact to={`${homePageUrl}waterfileration`} className="detail-link">3M</NavLink> | 
                            <NavLink exact to={`${homePageUrl}vaillant`} className="detail-link"> Vaillant </NavLink> | <NavLink exact to="/ebara" className="detail-link">Ebara</NavLink> |
                            <NavLink exact to={`${homePageUrl}smith`} className="detail-link"> AO Smith </NavLink>

                            {/* <NavLink exact to="/clients/tbs-website/waterfileration" className="detail-link">3M</NavLink> | 
                            <NavLink exact to="/clients/tbs-website/vaillant" className="detail-link"> Vaillant </NavLink> | <NavLink exact to="/clients/tbs-website/ebara" className="detail-link">Ebara</NavLink> |
                            <NavLink exact to="/clients/tbs-website/smith" className="detail-link"> AO Smith </NavLink> */}

                            </span>
                        </div>
                    </div>
                    <div>
                        <div class="feature-details">
                            <span className="specification">Subcategories:&nbsp;</span>
                            <span className="spec-detail2">Water Heating | Water Filtration | Water Pressure</span>
                        </div>
                    </div>
                    
                   

                </div>
                </Col>
                    </Row>
            </div>
            <SRLWrapper>
                <div className="bs-masonry">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 992: 3 }} >
                        <Masonry gutter="20px">
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                                <a href={watersolutions_large1}>
                                    <img src={watersolutions1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                            <a href={watersolutions_large2}>
                                    <img src={watersolutions2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                            <a href={watersolutions_large8}>
                                    <img src={watersolutions8} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="500">
                            <a href={watersolutions_large3}>
                                    <img src={watersolutions3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="600">
                            <a href={watersolutions_large4}>
                                    <img src={watersolutions4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="700">
                            <a href={watersolutions_large5}>
                                    <img src={watersolutions5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                             
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                            <a href={watersolutions_large6}>
                                    <img src={watersolutions6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                         
                           
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="900">
                            <a href={watersolutions_large7}>
                                    <img src={watersolutions7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            
                            
                            
                            
                           
                            
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </SRLWrapper>
            </Container>
        </div>
    );
}

export default Surfaces;