
import {NavLink } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import React, { useEffect, useState } from "react";
import Bathroom from '../images/prod-banner/bathrooms.jpg';
import Doors from '../images/prod-banner/doors.jpg';
import Windows from '../images/prod-banner/windows.jpg';
import Facades from '../images/prod-banner/facades.jpg';

import Railing from '../images/prod-banner/railing_and_decking.jpg';
import Surfaces from '../images/prod-banner/surfaces.jpg';
import WaterSol from '../images/prod-banner/watersolutions.jpg';
import { homePageUrl } from "../module/Constants";
const Products = () => {

  // const [mouseOver,setMouseOver]=useState('green');
  //const [mouseOver2,setMouseOver2]=useState('green');
  const [transform,setTransform]=useState('translateY(0%)');

  const  MouseOver=( hover)=> {
   // event.target.style.background = 'red';
   if(hover==1){
    // setMouseOver('blue');
    // setTransform('translateY(15%)');
   }
   else if(hover==2) {
    //  setMouseOver('pink')   
     setTransform('translateY(-12%)');
    }
    else {
    // setMouseOver('red')  
    setTransform('translateY(-14%)');  
  }
  }
  const MouseOut=(hoverOut)=>{
   // event.target.style.background="";
   // setMouseOver('green')
   //alert(hoverOut)
   if(hoverOut==1){
  //  setMouseOver('green');
   setTransform('translateY(0%)');
   }

  else if(hoverOut==2) {
    // setMouseOver('green');
    setTransform('translateY(0%)');
  }

   else {
  //  setMouseOver('green')
   setTransform('translateY(0%)');
   }
  }
  
    return (
     <div className="bs-product lyt-section">
        <Container>
        <Row>
          <Col lg={2} md={2} sm={12}>
        
          <div className="bs-heading" data-aos="slide-up" data-aos-once="false" data-aos-delay="50">
            <h2 className="title typ-sml typ-cps">categories</h2>
          </div>
        {/* <div className="title-container">
          <span>categories</span>
        </div> */}
       </Col>
       </Row>
       <Row>
          <Col lg={12} md={12} sm={12}   >
        <div className="products-lists" id="prod-div">
          <div className ="scroll-list">
            <ul className="links">
              <li className="bathtitle" id="b-title"  onMouseOver={()=>MouseOver(1)} onMouseOut={()=>MouseOut(1)}>
                <div className="list">
                  {/* <NavLink exact to="/clients/tbs-website/bathroom" data-text="Bathrooms">Bathrooms<sup>26</sup></NavLink> */}
                  <NavLink exact to={`${homePageUrl}bathroom`}  data-text="Bathrooms">Bathrooms<sup>26</sup></NavLink>
                </div>
                <div className="bgImg typ-bathimg">
                  <img src={Bathroom} />
                </div>
              </li>
              <li>
                <div className="list">
                  {/* <NavLink exact to="/clients/tbs-website/windows" data-text="Windows">Windows<sup>20</sup></NavLink> */}
                  <NavLink exact to={`${homePageUrl}windows`} data-text="Windows">Windows<sup>20</sup></NavLink>
                </div>
                <div className="bgImg typ-windowimg">
                  <img src={Windows} />
                </div>
              </li>
              <li>
                <div className="list">
                  {/* <NavLink exact to="/clients/tbs-website/doors"  data-text="Doors">Doors<sup>06</sup></NavLink> */}
                  <NavLink exact to={`${homePageUrl}doors`}  data-text="Doors">Doors<sup>06</sup></NavLink>
                </div>
                <div className="bgImg typ-doorimg">
                  <img src={Doors} />
                </div>
              </li>
              
              <li>
                <div className="list">
                  {/* <NavLink exact to="/clients/tbs-website/facades" data-text="Facades">Facades<sup>08</sup></NavLink> */}
                  <NavLink exact to={`${homePageUrl}facades`} data-text="Facades">Facades<sup>08</sup></NavLink>
                </div>
                <div className="bgImg typ-facadesimg">
                  <img src={Facades} />
                </div>
              </li>
              <li>
                <div className="list">
                  {/* <NavLink exact to="/clients/tbs-website/railing" data-text="Railings and decking">Railings and Decking<sup>12</sup></NavLink> */}
                  <NavLink exact to={`${homePageUrl}railing`} data-text="Railings and decking">Railings and Decking<sup>12</sup></NavLink>
                </div>
                <div className="bgImg typ-railingimg">
                  <img src={Railing} />
                </div>
              </li>
              <li className="surfacetitle" onMouseOver={()=>MouseOver(2)} onMouseOut={()=>MouseOut(2)}>
                <div className="list">
                  {/* <NavLink exact to="/clients/tbs-website/surfaces" data-text="Surfaces">Surfaces<sup>10</sup></NavLink> */}
                  <NavLink exact to={`${homePageUrl}surfaces`} data-text="Surfaces">Surfaces<sup>10</sup></NavLink>
                </div>
                <div className="bgImg typ-surfaceimg">
                  <img src={Surfaces} />
                </div>
              </li>
              <li className="watersoltitle" onMouseOver={()=>MouseOver(3)} onMouseOut={()=>MouseOut(3)}>
                <div className="list">
                  {/* <NavLink exact to="/clients/tbs-website/watersolutions" data-text="Water Solutions">Water Solutions<sup>08</sup></NavLink> */}
                  <NavLink exact to={`${homePageUrl}watersolutions`} data-text="Water Solutions">Water Solutions<sup>08</sup></NavLink>
                </div>
                <div className="bgImg typ-watersolimg">
                  <img src={WaterSol} />
                </div>
              </li>
            </ul>
          </div>
        </div>
        </Col>
          </Row>
     </Container>
     </div>
    );


  };
  
  export default Products;