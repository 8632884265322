import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { homePageUrl } from '../module/Constants.js';
import { Container,Containerfluid, Row, Col } from 'reactstrap';

import Aos from "aos";
import "aos/dist/aos.css";
import { SRLWrapper } from "simple-react-lightbox";
//Images
import backArrow from "../images/backArrow.png";
import Railing1 from "../images/Products/Railing1.jpg";
import Railing2 from "../images/Products/Railing2.jpg";
import Railing3 from "../images/Products/Railing3.jpg";
import Railing4 from "../images/Products/Railing4.jpg";
import Railing5 from "../images/Products/Railing5.jpg";
import Railing6 from "../images/Products/Railing6.jpg";
import Railing7 from "../images/Products/Railing7.jpg";
import Railing8 from "../images/Products/Railing8.jpg";
import Railing9 from "../images/Products/Railing9.jpg";
import Railing10 from "../images/Products/Railing10.jpg";
import Railing11 from "../images/Products/Railing11.jpg";
import Railing12 from "../images/Products/Railing12.jpg";

// large

import Railing_Large1 from "../images/Products/large/Railing1.jpg";
import Railing_Large2 from "../images/Products/large/Railing2.jpg";
import Railing_Large3 from "../images/Products/large/Railing3.jpg";
import Railing_Large4 from "../images/Products/large/Railing4.jpg";
import Railing_Large5 from "../images/Products/large/Railing5.jpg";
import Railing_Large6 from "../images/Products/large/Railing6.jpg";
import Railing_Large7 from "../images/Products/large/Railing7.jpg";
import Railing_Large8 from "../images/Products/large/Railing8.jpg";
import Railing_Large9 from "../images/Products/large/Railing9.jpg";
import Railing_Large10 from "../images/Products/large/Railing10.jpg";
import Railing_Large11 from "../images/Products/large/Railing11.jpg";
import Railing_Large12 from "../images/Products/large/Railing12.jpg";

const Railing = () => {

    useEffect(() => {
        Aos.init({ duration: 2000, delay: 2 });
    });

    return (
        <div className="lyt-section">
            
            <Container>
               
            <div className="bs-hero">
            <Row>
                    <Col>
                        <div className="bs-arrow back">
                            {/* <NavLink exact to="/clients/tbs-website/products"><i className="icon icon-Line"></i></NavLink> */}
                            <NavLink exact to={`${homePageUrl}products`} ><i className="icon icon-Line"></i></NavLink>
                        </div>
                    </Col>
                </Row>
            <Row>
            <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} >
                <div className="heading">
                    <span className="title"> Railings and Decking<sup>12</sup></span>
                </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                <div className="description">
                    <div>
                    <h3 className="desc-title">An elevated way of life.</h3>
                    </div>
                    <div>
                        <p>
                        Much more than just an elementary home feature, wooden railings and deckings can adhere to a variety of architectural styles. It’s a great way to create a cozy niche indoors, or expand your home experience to the outdoors. A more contemporary staircase, a chic terrace space, or simply, a sophisticated solution for an outdoor deck - it’s everything you need to make an elevated choice.
                        </p>
                    </div>
                    <div className="feature-details">
                        <div>
                            <span className="specification">Featured Brands:&nbsp;</span> 
                            <span className="spec-detail1">
                                <NavLink exact to={`${homePageUrl}ritikaawood`}className="detail-link">Ritikaawood</NavLink>
                                {/* <NavLink exact to="/clients/tbs-website/ritikaawood" className="detail-link">Ritikaawood</NavLink> */}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div class="feature-details">
                            <span className="specification">Materials:&nbsp;</span>
                            <span className="spec-detail2">  Wood </span>
                        </div>
                    </div>
                   


                </div>
                </Col>
                    </Row>
            </div>
            <SRLWrapper>
                <div className="bs-masonry">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 992: 3 }} >
                        <Masonry gutter="20px">
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                                <a href={Railing_Large1}>
                                    <img src={Railing1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                            <a href={Railing_Large2}>
                                    <img src={Railing2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                            <a href={Railing_Large3}>
                                    <img src={Railing3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="500">
                            <a href={Railing_Large4}>
                                    <img src={Railing4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="600">
                            <a href={Railing_Large5}>
                                    <img src={Railing5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="700">
                                <a href={Railing_Large10}>
                                    <img src={Railing10} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                            <a href={Railing_Large6}>
                                    <img src={Railing6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="900">
                                <a href={Railing_Large11}>
                                    <img src={Railing11} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1000">
                            <a href={Railing_Large7}>
                                    <img src={Railing7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1100">
                            <a href={Railing_Large12}>
                                    <img src={Railing12} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1200">
                            <a href={Railing_Large9}>
                                    <img src={Railing9} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1300">
                            <a href={Railing_Large8}>
                                    <img src={Railing8} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </SRLWrapper>
            </Container>
        </div>
    );
}

export default Railing;