import {NavLink } from "react-router-dom";

import { useHistory } from "react-router-dom";
//Images
import backArrow from "../images/backArrow.png";
import brandImage5 from "../images/Brands/brandImage5.png";

import brandDownload from "../images/Brands/brandDownload.png";

import { Container,Containerfluid, Row, Col } from 'reactstrap';

import FenestaCatelogue1 from "../pdf/fenesta-1.pdf";
import FenestaCatelogue2 from "../pdf/fenesta-2.pdf";
import FenestaCatelogue3 from "../pdf/fenesta-3.pdf";


const Fenesta = (props) =>{
    let history = useHistory();
    return(
        
        <div className="">
        <Container fluid className="brand-detl">
        <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                        <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                            <i className="icon icon-Line"></i>                        
                    </div>
                        <div className="content">
                            <img src={brandImage5} alt="Brand Image 1"/>
                            <div className="bs-heading" style={{marginTop:'20px'}}>
                                <h2 className="title typ-cps black">about the brand</h2>    
                            </div>
                            <p className="bs-para typ-normal para1">
                            Launched in 2003, Fenesta pioneered the uPVC window revolution in India, and has today firmly established itself as the leader in the Indian windows market.</p>

                            <p className="bs-para typ-normal para2">
                            The company has since, continued to harness global technologies to drive the design, manufacture and installation of 100% customised uPVC and Aluminium windows and doors - while installing over 2.5 million units. What’s more, it has firmly established itself as India’s No. 1 brand for windows and doors, and boasts of a presence in more than 327 cities across India, Bhutan and Nepal.
                            </p>

                            <div className="bs-heading">
                                <h2 className="title typ-cps black">About the association </h2>
                            </div>
                            <p className="bs-para typ-normal para3">
                            A partnership that has flourished since the year 2009, it is one in which TBS features as the largest dealer partner for the brand in the western region. And in addition, is only one of three exclusive dealers for the brand in Pune. In fact, TBS’ showroom was the first of its kind in India to create and display approximately 30 window concepts in store. An idea that in many ways redefined the window retail experience in the country.
                            </p>
                            
                            <div className="bs-heading">
                                <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                            </div>
                            <div className="catalogue-desc bs-para">
                            
                                    <a href={FenestaCatelogue1} download="FenestaCatelogue1" target='_blank'>
                                    <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Aluminium&nbsp;Retail&nbsp;Brochure</p>
                                        <i className="dwnload icon-download"></i>
                                    </a>
                            </div>
                            <div className="catalogue-desc bs-para">
                            
                                    <a href={FenestaCatelogue2} download="FenestaCatelogue" target='_blank'>
                                    <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                Door&nbsp;Brochure</p>
                                        <i className="dwnload icon-download"></i>
                                    </a>
                            </div>
                            <div className="catalogue-desc bs-para">
                                
                                    <a href={FenestaCatelogue3} download="FenestaCatelogue3" target='_blank'>
                                    <p className="bs-para list">
                                Retail&nbsp;Brochure</p>
                                        <i className="dwnload icon-download"></i>
                                    </a>
                            </div>
                        </div>
                    
                    </Col>  

                    <Col lg={6} md={12} sm={12} >
                        <div className="image">
                            <iframe src="https://youtube.com/embed/Jw1nQQ140Ok" title="description" width="100%" height="100%"></iframe>
                        </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Fenesta;