import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, } from 'reactstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import { TimelineLite, Power2 } from 'gsap';
import { SRLWrapper } from "simple-react-lightbox";


import CSSRulePlugin from 'gsap/CSSRulePlugin';
// import {  Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

//Animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";



import Aboutimg1 from '../images/About/about1.jpg';
import Aboutimg2 from '../images/About/about2.jpg';
import t1 from '../images/About/Ganesh Salle_Plumbing Services Division Head.jpg';
import t2 from '../images/About/Husain Quettawalla_Co_Founder and Partner.png';
import t3 from '../images/About/Pooaj Sogani_Co-Founder_Surfaces Division Head.jpg';
import t4 from '../images/About/Rahil Pathan_Windows and Doors Division Head.jpg';
import t5 from '../images/About/Reena Rebello_Partner.jpg';
import t6 from '../images/About/Sachin Jaiswal_Co_Founder and  Ex-Managing Partner.jpg';
import t7 from '../images/About/Santosh Kapdekar_Bathrooms Division Head.jpg';
import t8 from '../images/About/Vijay Natarajan _ CEO.jpg';


import about_us_catalogue from "../pdf/about_us_catalogue.pdf";

function ReadMore({ children, maxCharacterCount = 100 }) {
  const text = children;
  const [isTruncated, setIsTruncated] = useState(true);
  const resultString = isTruncated ? text.slice(0, maxCharacterCount) : text;
  function toggleIsTruncated() {
    setIsTruncated(!isTruncated);
  }
  return (
    <p className="bs-para readmore">
      {resultString}
      {/* <span className="toggle" onClick={toggleIsTruncated}>{isTruncated ? "...Read More" : "Read Less"}</span> */}

      <span className="toggle" onClick={toggleIsTruncated}>{isTruncated ? <i className="icon-forward"> </i> : <i className="icon-back"> </i>}</span>
    </p>
  )
}

const About = (props) => {


  let image = useRef(null);
  let container = useRef(null);

  let image2 = useRef(null);
  let container2 = useRef(null);

  let imageReveal = CSSRulePlugin.getRule(".img-container:after , .img-wrapper:after");

  let tl = new TimelineLite();

  useEffect(() => {
    tl.set(container, { autoAlpha: 1 });
    tl.from(container, 2, {
      xPercent: -100,
      ease: Power2.out
    });
    tl.from(image, 2, {
      xPercent: 100,
      width: '100%',
      // scale: 1.3,
      delay: -2,
      ease: Power2.out
    });

    tl.set(container2, { autoAlpha: 1 });
    tl.from(container2, 2, {
      xPercent: -100,
      ease: Power2.out
    });
    tl.from(image2, 2, {
      xPercent: 100,
      width: '100%',
      // scale: 1.3,
      delay: -2,
      ease: Power2.out
    });
  });

  useEffect(() => {
    Aos.init({ duration: 1000, disable: 'mobile', delay: 1 });
  })

  const images = [
    {
      src: { t1 },
      caption: 'Lorem ipsum dolor sit amet',
      width: 1920,
      height: 'auto'
    },
    {
      src: { t2 },
      thumbnail: { t2 },
      caption: 'Commodo commodo dolore',
      width: 1024,
      height: 'auto'
    },
    {
      src: { t3 },
      thumbnail:
        { t3 },
      caption: 'Vimeo video',
      autoplay: false,
      showControls: true
    }
  ]

  const captionOne = (
    <div >
      She found herself in a <span >forest...</span>
    </div>
  )
  const captionTwo = (
    <div >
      ...lost and wandering she had to
      <span >make a choice...</span>
    </div>
  )
  const customCaptions = [
    { id: 0, caption: 'One' },
    { id: 1, caption: captionTwo },

  ]
  return (

    <section className="">
      <div className="lyt-section about-sec">
        <Container>
          {/* <div className="bs-heading">
            <h2 className="title typ-sml">About Us</h2>
          </div> */}
          <Row>
            <Col lg={2} md={2} sm={12}>
              <div className="bs-heading" data-aos="slide-up" data-aos-once="false" data-aos-delay="50">
                <h2 className="title typ-sml typ-cps">About Us</h2>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div className="bs-heading">
                <p className="title typ-medium">“Our lives are marked by the spaces we live in, and the spaces we create.”</p>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="bs-para typ-normal about-desc">
                <p> Founded in 2009, with the vision of providing access to the finest sanitaryware and bathroom products, TBS has always epitomised the enduring power of elegance, timeless style and exquisite luxury.</p>
                <p>
                  A carefully curated portfolio of exclusive world brands aligned with a contemporary approach - to showcase products and services that were meant for modern, functional and long-lasting spaces.
                </p>
                <p>
                  While understanding the world of design, innovation and sustainability however, we recognised that inspiration doesn't just deliver excellence, but also infinite possibilities. And as a result, our carefully curated collection has gone beyond bathrooms to cover other key interior concepts such as doors, windows, facades and surfaces.
                </p>
                <p>

                  Little wonder then, the name today has garnered trust and credibility as one of Maharashtra’s leading luxury fittings and interior brands, based out of Pune. TBS, isn’t just the preferred choice for leading designers and architects, it has also found favour with esteemed clientele and dealers across the country. </p>
                <div className="catalogue-desc bs-para catelogue typ-about-us">
                  <a href={about_us_catalogue} download="about_us_catalogue" target='_blank' className='about-para'>
                    <p className="bs-para list " style={{ borderBottom: '2px solid rgba(0, 0, 0, 0.1)', fontWeight: 'bold' }}>
                    Download TBS Profile </p>

                    <i className="dwnload icon-download"></i>
                  </a>
                </div>

              </div>

            </Col>
          </Row>
          {/* <Row ref={el => container = el} className="container abt-img">
              <div className="img-wrapper ">
                <img ref={el => image = el} src={Aboutimg1} />
              </div>
            </Row>   */}
          {/* <Row data-aos="fade-right" data-aos-once="false" data-aos-delay="10">
              <div className="img-wrapper reveal">
                <img src={Aboutimg1} />
              </div>
            </Row>   */}

          {/* <Row> */}
          <Row className="main">
            <div className="container" ref={el => (container = el)}>
              <>
                <div className="img-container">
                  <img
                    ref={el => {
                      image = el;
                    }}
                    src={Aboutimg1}
                  />
                </div>
              </>
            </div>
          </Row>
          {/* </Row> */}
          <div className="bs-para">
            <Row>
              <Col lg={6} md={6} sm={12} >
                <div className="bs-heading" data-aos="fade-right" data-aos-once="false">
                  <p className="title typ-big">Core <span className="line-break">Values</span></p>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="desc typ-bold" data-aos="fade-left" data-aos-once="false">
                  <p>
                    Our core values create an unwavering guide in order to shape a work culture that aims for inspiration and executional excellence.
                  </p>
                </div>
              </Col>
            </Row>

          </div>
        </Container>

        <Container fluid>

          <Row className="align-center main">
            <Col lg={6} md={6} sm={12} className="p0">
              {/* <div className="img-fluid" data-aos="fade-right" data-aos-once="false">
                <img src={Aboutimg2} />
              </div>    */}

              <div className="container2 img-fluid p0" ref={el => (container2 = el)}>
                <>
                  <div className="img-wrapper">
                    <img
                      ref={el => {
                        image2 = el;
                      }}
                      src={Aboutimg2}
                    />
                  </div>
                </>
              </div>

            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="mod-img-with-txt">
                <div className="icon-with-txt" data-aos="fade-left" data-aos-once="false">
                  <i class="icon icon-Swatches"></i>
                  <p>Be service oriented.</p>
                </div>
                <div className="icon-with-txt" data-aos="fade-left" data-aos-once="false">
                  <i class="icon icon-Handshake"></i>
                  <p>Build Relationships.</p>
                </div>
                <div className="icon-with-txt" data-aos="fade-left" data-aos-once="false">
                  <i class="icon icon-LightbulbFilament"></i>
                  <p>Share Expertise.</p>
                </div>
                <div className="icon-with-txt" data-aos="fade-left" data-aos-once="false">
                  <i class="icon icon-ShieldCheck"></i>
                  <p>Act with Integrity.</p>
                </div>
                <div className="icon-with-txt" data-aos="fade-left" data-aos-once="false">
                  <i class="icon icon-Leaf"></i>
                  <p>Practice Sustainability</p>
                </div>
              </div>
            </Col>
          </Row>


        </Container>
        {/* <SRLWrapper elements={elements} /> */}
        {/* <SRLWrapper images={images}>
        <img className="slideImage" src={t1}  />
        <img className="slideImage" src={t2} />
          </SRLWrapper>
         */}
        <Container fluid>
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12} className="p0">
                <div className="bs-heading" data-aos="fade-right" data-aos-once="false" >
                  <p className="title typ-big">Our <span className="line-break">Team</span></p>
                </div>
              </Col>

            </Row>
          </Container>
          <Row className="mod-team-sec p0">
            <SRLWrapper showThumbnailsButton={false}>
              <Swiper data-aos="fade-up" data-aos-once="false"
                spaceBetween={0} loop={true}
                navigation={true}
                autoplay={true}
                mouseDrag={false}
                breakpoints={{

                  0: {
                    slidesPerView: 1,
                  },
                  350: {
                    slidesPerView: 2,
                  },
                  480: {
                    slidesPerView: 3,
                  },

                  640: {
                    slidesPerView: 4,
                  }

                }}
                className="team-container">
                <SwiperSlide className="teamslide">
                  <img className="slideImage" src={t6} alt="Sachin Jaiswal" />
                  <div className="slidecaption">
                    <p className="name">Sachin Jaiswal <a href="https://www.linkedin.com/in/sachinsjaiswal/" target="_blank"> <i className="icon icon-LinkedinLogo"></i> </a></p>
                    <p className="designation">Co-Founder and  Partner</p>

                    <ReadMore>
                      An entrepreneur with more than 18 yeas of experience in sales,
                      consumer loans and products, Sachin is a pivotal member
                      of the TBS think tank. Prior to his new role as Business Head,
                      Aquagreen Solutions, he was Founding and Managing Partner of TBS.
                      As a forward thinking retail leader, he played an integral role in
                      creating key relationships with manufacturers, architects and industry
                      associations.
                    </ReadMore>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="teamslide">
                  <img className="slideImage" src={t3} alt="Pooja Sogani" />
                  <div className="slidecaption">
                    <p className="name">Pooja Sogani  <a href="https://www.linkedin.com/in/pooja-sogani-142084212/" target="_blank"> <i className="icon icon-LinkedinLogo"></i> </a></p>
                    <p className="designation">Co-Founder Surfaces Division Head</p>
                    <ReadMore>
                      As head of the Surfaces Division at TBS,
                      Pooja’s operational management is a key asset to the team.
                      That said, it’s really her exceptional ability
                      as a creative individual that sets her apart.
                      Having studied interior design,
                      she’s often the one lending her deft vision to key collaborations
                      with clients, architects and other interior designers.
                    </ReadMore>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="teamslide">
                  <img className="slideImage" src={t2} alt="Husain Quettawalla" />
                  <div className="slidecaption">
                    <p className="name">Husain Quettawalla <a href="https://www.linkedin.com/in/husain-quettawalla-27a7a676/" target="_blank"> <i className="icon icon-LinkedinLogo"></i> </a></p>
                    <p className="designation">Co-Founder and Partner</p>
                    <ReadMore>
                      Husain’s career has been dotted with innumerable professional
                      successes across diverse industries including finance,
                      telecom and media. Having lived in over 20 countries,and 4 continents,
                      it is his entrepreneurial instinct that helped him establish
                      a successful business in building materials.
                      Today, he is the founder and CEO of Aquagreen Solutions,
                      and supports the group’s retail business TBS, on a strategic front,
                      as a partner.
                    </ReadMore>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="teamslide">
                  <img className="slideImage" src={t5} alt="Reena Rebello" />
                  <div className="slidecaption">
                    <p className="name">Reena Rebello  <a href="https://www.linkedin.com/in/reena-rebello-80b1a1212/" target="_blank"> <i className="icon icon-LinkedinLogo"></i> </a></p>
                    <p className="designation">Partner</p>
                    <ReadMore>
                      A dynamic professional with over 20 years of
                      extensive experience in strategic planning and customer
                      relationship management, Reena is a specialist from the domain of Telecom and Real Estate.
                      Her expertise ranges from managing customer life cycles to managing teams
                      in order to deliver  on both, business and individual goals. Reena’s presence
                      on the team goes a long way in streamlining cross function as well implementing
                      key strategic measures.
                    </ReadMore>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="teamslide">
                  <img className="slideImage" src={t8} alt="Vijay Natarajan" />
                  <div className="slidecaption">
                    <p className="name">Vijay Natarajan  <a href="https://www.linkedin.com/in/vijay-natarajan-a8560318/" target="_blank"><i className="icon icon-LinkedinLogo"></i> </a></p>
                    <p className="designation">CEO</p>
                    <ReadMore>
                      An exponent of the real estate industry,
                      Vijay has proven himself as a leader with a demonstrated
                      history of sales, project
                      and team management. His professional calibre of course,
                      comes well complemented with a passion for travel, design
                      interiors and construction. Today, he successfully combines his
                      ability to manage an enterprise and provide a strategic vision while
                      playing the role of CEO at TBS.
                    </ReadMore>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="teamslide">
                  <img className="slideImage" src={t7} alt="Santosh Kapdekar" />
                  <div className="slidecaption">
                    <p className="name">Santosh Kapdekar <a href="https://www.linkedin.com/in/santosh-kapdekar-06b229211/" target="_blank"><i className="icon icon-LinkedinLogo"></i> </a></p>
                    <p className="designation">Bathrooms Division Head</p>
                    <ReadMore>
                      Mr. Cool, Santosh Kapdekar doesn't just bring maturity and
                      experience to his role as Manager of the Bathrooms Division - he
                      also brings about a structure and sense of organisation
                      that enables operational improvement and a transformed
                      customer experience.
                    </ReadMore>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="teamslide">
                  <img className="slideImage" src={t4} alt="Rahil Pathan" />
                  <div className="slidecaption">
                    <p className="name">Rahil Pathan  <a href="https://www.linkedin.com/in/rahil-pathan-1b394a115/" target="_blank"> <i className="icon icon-LinkedinLogo"></i> </a></p>
                    <p className="designation">Windows and Doors Division Head</p>
                    <ReadMore>
                      While TBS has consistently been the largest dealer
                      in the western region for Fenesta,
                      it is owing to employees like Rahil,
                      who deliver consistently on their sales targets.
                      And it is efforts such as these that have ensured the
                      establishment of our windows division, alongside an expansion
                      into aluminium and wood.
                    </ReadMore>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="teamslide">
                  <img className="slideImage" src={t1} alt="Ganesh Sale" />
                  <div className="slidecaption">
                    <p className="name"  >Ganesh Salle <a href="https://www.linkedin.com/in/ganesh-salle-b2770674/" target="_blank"><i className="icon icon-LinkedinLogo"></i> </a> </p>
                    <p className="designation">Plumbing Services Division Head</p>
                    <ReadMore>
                      An avid football lover, Ganesh has been a key team member for
                      over 10 years now,
                      and has stopped at nothing to ensure
                      TBS met its goals to establish a services division.
                      Today, he single handedly manages plumbing services with one of our
                      biggest client accounts, DY Patil.
                    </ReadMore>
                  </div>
                </SwiperSlide>
              </Swiper>
            </SRLWrapper>
          </Row>

        </Container>
      </div>
    </section>
  );
};

export default About;
