import {NavLink } from "react-router-dom";


import { Container,Containerfluid, Row, Col } from 'reactstrap';

import { useHistory } from "react-router-dom";

//Images
import backArrow from "../images/backArrow.png";
import brandImage9 from "../images/Brands/brandImage9.png";

import brandDownload from "../images/Brands/brandDownload.png";

import Smithcatelogue1 from "../pdf/a.o.smith-1.pdf";
import Smithcatelogue2 from "../pdf/a.o.smith-2.pdf";

const Smith = (props) =>{
    let history = useHistory();
    return(
            <div className="">
               <Container fluid className="brand-detl">
        <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                        <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                            <i className="icon icon-Line"></i>                        
                    </div>
                        <div className="content">
                        <img src={brandImage9} alt="Brand Image 1"/>
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">Set up in 1874, A.O. Smith Corporation is North America’s largest manufacturer of Water Heaters. Designed for years of trouble-free service, A. O. Smith Water Heaters are distributed in over 60 countries and remains the preferred choice of contractors, architects and specifying engineers in North America, Europe and Asia.</p>

                        <p className="bs-para typ-normal para2">
                        The company’s entry into India was formalised with the incorporation of A.O Smith India, in 2006 with manufacturing facilities set up in Harohalli, Bengaluru - while catering to a market that covers water heating, heat pumps, storage water heaters and drinking water filtration.
                        </p>

                        <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal para3">
                        TBS has been associated with AO Smith since 2018 via Aquagreen. The association focusses on its renewable energy hot water solution – the heat pump.
                        </p>
                        
                        <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                        <a href={Smithcatelogue1} download="Smithcatelogue1" target='_blank'>
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                A.O.Smith Master Catalogue Water Heater</p>
                              
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                       
                        <div className="catalogue-desc bs-para">
                           
                                <a href={Smithcatelogue2} download="Smithcatelogue2" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                            A.O.Smith&nbsp;Master&nbsp;Brochure&nbsp;Heat&nbsp;Pump</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                        {/* <div className="catalogue-desc bs-para">
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                    </div>
                    </Col>  

                <Col lg={6} md={12} sm={12}>
                    <div className="image">
                        <iframe src="https://youtube.com/embed/UTP26RpMSeI" title="description" width="100%" height="100%"></iframe>
                    </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Smith;