import {useEffect , useRef} from "react";
import {NavLink} from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Row, Col,} from 'reactstrap';

import { homePageUrl } from "../module/Constants";

//Animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";

//Images
import nextArrow from "../images/nextArrow.png";

//

import backArrow from "../images/backArrow.png";
import Bathroom1 from "../images/Projects/bathroom1.jpg";
import Proj2Img1 from "../images/Projects/Proj2Img1.jpg";
import Proj4Img1 from "../images/Projects/RitikaaWood_CoffeeTableBook_draft14_HiRes_37-44_Opolis-11.jpg";
import Proj5Img1 from "../images/Projects/Studio Hinge Pune apt-52.jpg";
import Proj6Img1 from "../images/Projects/DT5A7459.jpg";
import Proj7Img1 from "../images/Projects/DT5A3038.jpg";

const Projects = () => {
    const ref = useRef(null);
    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
          ref.current.swiper.slideNext();
        }
    };
    const goPrev = () => {
      if (ref.current !== null && ref.current.swiper !== null) {
        ref.current.swiper.slidePrev();
      }
    };
    useEffect(()=>{
      Aos.init({duration: 2000, disable: 'mobile', delay: 200});
    });

    return (
      <div className="lyt-section bs-project">
        <Container>
        <div className="content">
          {/* <Row>
            <Col>
            <div className="bs-heading">
              <div className="title typ-cps" data-aos="slide-up" data-aos-once="false">Projects</div>              
            </div>
            </Col>
          </Row> */}
          <Row>
            <Col>
                <div className="bs-heading sm-center"  data-aos="slide-up" data-aos-once="false" data-aos-delay="50">
                    <div className="title typ-sml typ-cps">Projects</div>
                </div>
            </Col>
          </Row>
          <div className="bs-para typ-normal">
              <p>
              TBS welcomes you into a world of spaces that have been created on the foundations 
              of design, aesthetics, craft, and most importantly, identity. Our selection of projects 
              on view, are the result of collaborations with leadings designers, architects and developers
              - who live and breathe home and office environments just the way we do. The result? Defying
              convention with innovative ideas to deliver the unique, the remarkable and the contemporary.
              </p>
          </div>
        </div>

        {/* <div className="slider-arr">
            <div className="bs-arrow slider-next">
                  <img src={nextArrow} alt="Next Arrow" onClick={goNext} style={{width:"13px"}}/>
              </div>
            <div className="bs-arrow slider-prev">
              <img src={backArrow} alt="back Arrow" onClick={goPrev} style={{width:"13px"}}/>
            </div>
          </div> */}
          
        <Swiper ref={ref} loop={true} navigation={true} breakpoints={{
                
                0: {
                  slidesPerView: 1,
                },
                350:{
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 2,
                }

              }} >
          <SwiperSlide>
            <div className="image img1">
              <div className="">
                <p className="bs-para black cm mtop-25" >
                Project featuring Architect Dhruva Samal 

                </p>
                {/* <NavLink exact to="/clients/tbs-website/architect_dhruva_samal_bathrooms_projects">
                  <img src={Bathroom1} alt="Project 1" className="cm mtop-25"/>
                </NavLink> */}
                <NavLink exact to={`${homePageUrl}architect_dhruva_samal_bathrooms_projects`}>
                  <img src={Bathroom1} alt="Project 1" className="cm mtop-25"/>
                </NavLink>
              </div>
              
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="image img2">
              <div className="">
                <p className="bs-para black cm mtop-25" >
                Project featuring Architect Prakash Jadhav
                </p>
                {/* <NavLink exact to="/clients/tbs-website/architect_prakash_jhadav_sangli">
                  <img src={Proj2Img1} alt="Project 1" className="cm mtop-25"/>
                </NavLink> */}

                <NavLink exact to={`${homePageUrl}architect_prakash_jhadav_sangli`}>
                <img src={Proj2Img1} alt="Project 1" className="cm mtop-25"/>
                </NavLink>

              </div>
              
            </div>
          </SwiperSlide>
      
          <SwiperSlide>
          <div className="image img3">
              <div className="">
                <p className="bs-para black cm mtop-25" >
                  Project featuring Architect Pravir Sethi
                </p>
                {/* <NavLink exact to="/clients/tbs-website/pravir_sethi_kp_private_residence">
                  <img src={Proj5Img1} alt="Project 1" className="cm mtop-25"/>
                </NavLink> */}
                
                <NavLink exact to={`${homePageUrl}pravir_sethi_kp_private_residence`}>
                  <img src={Proj5Img1} alt="Project 1" className="cm mtop-25"/>
                </NavLink>
              </div>
              </div>
          </SwiperSlide>
          
         
        </Swiper>
        </Container>
      </div>
    );
  };
  
  export default Projects;