import {NavLink } from "react-router-dom";


import { Container,Containerfluid, Row, Col } from 'reactstrap';




import { homePageUrl } from '../module/Constants.js';

const ErrorPage = (props) =>{   return(  
    <div className="">
    <Container > 
            <div className="errorpage">
                <div>
                    <h2>404</h2>
                    <p className="errortitle">The page you requested could not found !</p>
                    <NavLink exact to={`${homePageUrl}`}>GO TO HOME PAGE</NavLink>
                </div>
            </div>
    </Container>
            </div>  

  )
}

export default ErrorPage;