import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Container,Containerfluid, Row, Col } from 'reactstrap';

import { homePageUrl } from '../module/Constants.js';
import React, {  useState } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link } from "react-scroll";
import { SRLWrapper } from "simple-react-lightbox";

//Animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";

// import images from '../images';

//Images
import backArrow from "../images/backArrow.png";

import Artist_Edition1 from "../images/Products/Artist_Edition1.jpg";
import Artist_Edition2 from "../images/Products/Artist_Edition2.jpg";
import Artist_Edition3 from "../images/Products/Artist_Edition3.jpg";
import Artist_Edition4 from "../images/Products/Artist_Edition4.jpg";
import Artist_Edition5 from "../images/Products/Artist_Edition5.jpg";
import Artist_Edition6 from "../images/Products/Artist_Edition6.jpg";

import Bath_Kitchen_Fixtures1 from "../images/Products/Bath_Kitchen_Fixtures1.jpg";
import Bath_Kitchen_Fixtures2 from "../images/Products/Bath_Kitchen_Fixtures2.jpg";
import Bath_Kitchen_Fixtures3 from "../images/Products/Bath_Kitchen_Fixtures3.jpg";
import Bath_Kitchen_Fixtures4 from "../images/Products/Bath_Kitchen_Fixtures4.jpg";
import Bath_Kitchen_Fixtures5 from "../images/Products/Bath_Kitchen_Fixtures5.jpg";
import Bath_Kitchen_Fixtures6 from "../images/Products/Bath_Kitchen_Fixtures6.jpg";
import Bath_Kitchen_Fixtures7 from "../images/Products/Bath_Kitchen_Fixtures7.jpg";
import Bath_Kitchen_Fixtures8 from "../images/Products/Bath_Kitchen_Fixtures8.jpg";
import Bath_Kitchen_Fixtures9 from "../images/Products/Bath_Kitchen_Fixtures9.jpg";
import Bath_Kitchen_Fixtures10 from "../images/Products/Bath_Kitchen_Fixtures10.jpg";
import Bath_Kitchen_Fixtures11 from "../images/Products/Bath_Kitchen_Fixtures11.jpg";
import Bath_Kitchen_Fixtures12 from "../images/Products/Bath_Kitchen_Fixtures12.jpg";


import Sanitaryware1 from "../images/Products/Sanitaryware1.jpg";
import Sanitaryware2 from "../images/Products/Sanitaryware2.png";
import Sanitaryware3 from "../images/Products/Sanitaryware3.jpg";
import Sanitaryware4 from "../images/Products/Sanitaryware4.jpg";

import Wellness1 from "../images/Products/Wellness1.jpg";
import Wellness2 from "../images/Products/Wellness2.jpg";
import Wellness3 from "../images/Products/Wellness3.jpg";
import Wellness4 from "../images/Products/Wellness4.jpg";

// large-iamges

import Artist_Edition_Large1 from "../images/Products/large/Artist_Edition1.jpg";
import Artist_Edition_Large2 from "../images/Products/large/Artist_Edition2.jpg";
import Artist_Edition_Large3 from "../images/Products/large/Artist_Edition3.jpg";
import Artist_Edition_Large4 from "../images/Products/large/Artist_Edition4.jpg";
import Artist_Edition_Large5 from "../images/Products/large/Artist_Edition5.jpg";
import Artist_Edition_Large6 from "../images/Products/large/Artist_Edition6.jpg";

import Bath_Kitchen_Fixtures_Large2 from "../images/Products/large/Bath_Kitchen_Fixtures2.jpg";
import Bath_Kitchen_Fixtures_Large3 from "../images/Products/large/Bath_Kitchen_Fixtures3.jpg";
import Bath_Kitchen_Fixtures_Large4 from "../images/Products/large/Bath_Kitchen_Fixtures4.jpg";
import Bath_Kitchen_Fixtures_Large5 from "../images/Products/large/Bath_Kitchen_Fixtures5.jpg";
import Bath_Kitchen_Fixtures_Large6 from "../images/Products/large/Bath_Kitchen_Fixtures6.jpg";
import Bath_Kitchen_Fixtures_Large7 from "../images/Products/large/Bath_Kitchen_Fixtures7.jpg";
import Bath_Kitchen_Fixtures_Large1 from "../images/Products/large/Bath_Kitchen_Fixtures1.jpg";
import Bath_Kitchen_Fixtures_Large8 from "../images/Products/large/Bath_Kitchen_Fixtures8.jpg";
import Bath_Kitchen_Fixtures_Large9 from "../images/Products/large/Bath_Kitchen_Fixtures9.jpg";
import Bath_Kitchen_Fixtures_Large10 from "../images/Products/large/Bath_Kitchen_Fixtures10.jpg";
import Bath_Kitchen_Fixtures_Large11 from "../images/Products/large/Bath_Kitchen_Fixtures11.jpg";
import Bath_Kitchen_Fixtures_Large12 from "../images/Products/large/Bath_Kitchen_Fixtures12.jpg";

import Sanitaryware_Large1 from "../images/Products/large/Sanitaryware1.jpg";
import Sanitaryware_Large2 from "../images/Products/large/Sanitaryware2.png";
import Sanitaryware_Large3 from "../images/Products/large/Sanitaryware3.jpg";
import Sanitaryware_Large4 from "../images/Products/large/Sanitaryware4.jpg";

import Wellness_Large1 from "../images/Products/large/Wellness1.jpg";
import Wellness_Large2 from "../images/Products/large/Wellness2.jpg";
import Wellness_Large3 from "../images/Products/large/Wellness3.jpg";
import Wellness_Large4 from "../images/Products/large/Wellness4.jpg";
// const images=[
//     {id:'1',imageName:'Wellness1.jpg',largeImage:{Wellness_Large1},tag:'free'},
//     {id:'2',imageName:'Wellness2.jpg',largeImage:{Wellness_Large2},tag:'one'},
//     {id:'3',imageName:'Wellness4.jpg',largeImage:{Wellness_Large4},tag:'one'},
//     {id:'4',imageName:'Wellness3.jpg',largeImage:{Wellness_Large3},tag:'free'},
//     {id:'5',imageName:'Wellness2.jpg',largeImage:{Wellness_Large2},tag:'two'},
//     {id:'6',imageName:'Wellness1.jpg',largeImage:{Wellness_Large1},tag:'two'},
//     {id:'7',imageName:'Wellness4.jpg',largeImage:{Wellness_Large4},tag:'free'},
//     {id:'8',imageName:'Wellness1.jpg',largeImage:{Wellness_Large1},tag:'free'},
//     {id:'9',imageName:'Wellness2.jpg',largeImage:{Wellness_Large2},tag:'four'},
//     {id:'10',imageName:'Wellness3.jpg',largeImage:{Wellness_Large3},tag:'four'},
//     {id:'11',imageName:'Wellness4.jpg',largeImage:{Wellness_Large4},tag:'two'}
// ];
const Bathroom = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, delay: 1, disable: 'mobile' });
    });

    // const [tag, setTag] = useState('all');
	// const [filteredImages, setFilteredImages] = useState([]);

	// useEffect(() => {
	// 		tag === 'all' ? setFilteredImages(images) : setFilteredImages(images.filter(image => image.tag === tag));
	// 	},
	// 	[tag]
	// );

    return (
        <div className="lyt-section">
            
            <Container>
                <div className="bs-hero">
                    <Row>
                        <Col>
                            <div className="bs-arrow back">
                                {/* <NavLink exact to="/clients/tbs-website/products"><i className="icon icon-Line"></i></NavLink> */}
                                <NavLink exact to={`${homePageUrl}products`}><i className="icon icon-Line"></i></NavLink>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} >
                <div className="heading">
                    <span className="title">Bathrooms<sup>26</sup></span>
                </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                <div className="description">
                    <div>
                        <h3 className="desc-title">Inspiration on tap. </h3>
                    </div>
                    <div>
                        <p>
                            Nothing spells exquisite luxury, quite like a stunning bathroom. Our collection of brands marry crisp lines and functional shapes, while accenting those little details - that can give your space an innovative edge and not to mention, timeless style.
                        </p>
                    </div>
                    <div>
                        <div className="feature-details">
                            <span className="specification typ-feature">Featured Brands:</span>
                            <span className="spec-detail2">
                                <NavLink exact to={`${homePageUrl}hangsrohe`} className="detail-link"> Hansgrohe </NavLink>| <NavLink exact to={`${homePageUrl}kolher`} className="detail-link">Kohler</NavLink>

                                {/* <NavLink exact to="/clients/tbs-website/hangsrohe" className="detail-link"> Hansgrohe </NavLink> | <NavLink exact to="/clients/tbs-website/kolher" className="detail-link">Kohler</NavLink> */}
 
                            </span>
                        </div>
                    </div>
                    <div className="feature-details">
                        <div className="desc">
                            <span className="specification">Subcategories:&nbsp; </span>
                            <span className="spec-detail1">
                                <div className="bs-scroll-links">
                                    <Link activeClass="active" to="Artist_Edition"
                                        spy={true} smooth={true} offset={-100} duration={1000}>
                                        Artist Edition |&nbsp;
                                    </Link>
                                    <Link activeClass="active" to="Bath_Kitchen_Fixtures"
                                        spy={true} smooth={true} offset={-100} duration={1000}>
                                        Bath and Kitchen Fixtures  |&nbsp;
                                    </Link>
                                    <Link activeClass="active" to="Sanitaryware"
                                        spy={true} smooth={true} offset={-100} duration={1000}>
                                        Sanitaryware |&nbsp;
                                    </Link>
                                    <Link activeClass="active" to="Wellness"
                                        spy={true} smooth={true} offset={-100} duration={1000}>
                                        Wellness
                                    </Link>
                                </div>

                            </span>
                        </div>
                    </div>

                </div>
                </Col>
                    </Row>
            </div> 


           
            <SRLWrapper>
                <div className="bs-masonry" >
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 992: 3 }}>
                        <Masonry gutter="20px">
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                                <a href={Artist_Edition_Large1}>
                                    <img src={Artist_Edition1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                                <a href={Artist_Edition_Large2}>
                                    <img src={Artist_Edition2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                                <a href={Artist_Edition_Large3}>
                                    <img src={Artist_Edition3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="500">
                                <a href={Artist_Edition_Large4}>
                                    <img src={Artist_Edition4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="600">
                                <a href={Artist_Edition_Large5}>
                                    <img src={Artist_Edition5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="700">
                                <a href={Artist_Edition_Large6}>
                                    <img src={Artist_Edition6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                                <a href={Bath_Kitchen_Fixtures_Large1}>
                                    <img src={Bath_Kitchen_Fixtures1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                                
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1000">
                                <a href={Bath_Kitchen_Fixtures_Large2}>
                                    <img src={Bath_Kitchen_Fixtures2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                               
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1100">
                                <a href={Bath_Kitchen_Fixtures_Large3}>
                                    <img src={Bath_Kitchen_Fixtures3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1200">
                                <a href={Bath_Kitchen_Fixtures_Large4}>
                                    <img src={Bath_Kitchen_Fixtures4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1300">
                                <a href={Bath_Kitchen_Fixtures_Large5}>
                                    <img src={Bath_Kitchen_Fixtures5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1400">
                                <a href={Bath_Kitchen_Fixtures_Large6}>
                                    <img src={Bath_Kitchen_Fixtures6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1500">
                                <a href={Bath_Kitchen_Fixtures_Large7}>
                                    <img src={Bath_Kitchen_Fixtures7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1600">
                                <a href={Bath_Kitchen_Fixtures_Large8}>
                                    <img src={Bath_Kitchen_Fixtures8} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1700">
                                <a href={Bath_Kitchen_Fixtures_Large9}>
                                    <img src={Bath_Kitchen_Fixtures9} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1800">
                                <a href={Bath_Kitchen_Fixtures_Large10}>
                                    <img src={Bath_Kitchen_Fixtures10} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1900">
                                <a href={Bath_Kitchen_Fixtures_Large11}>
                                    <img src={Bath_Kitchen_Fixtures11} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2000">
                                <a href={Bath_Kitchen_Fixtures_Large12}>
                                    <img src={Bath_Kitchen_Fixtures12} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2100">
                                <a href={Sanitaryware_Large1}>
                                    <img src={Sanitaryware_Large1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2200">
                                <a href={Sanitaryware_Large3}>
                                    <img src={Sanitaryware_Large3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2300">
                                <a href={Sanitaryware_Large2}>
                                    <img src={Sanitaryware_Large2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                               
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2400">
                                <a href={Sanitaryware_Large4}>
                                    <img src={Sanitaryware_Large4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2500">
                                <a href={Wellness_Large1}>
                                    <img src={Wellness_Large1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                                
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2600">
                                <a href={Wellness_Large2}>
                                    <img src={Wellness_Large2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                               
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2700">
                                <a href={Wellness_Large3}>
                                    <img src={Wellness_Large3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                                <a href={Wellness_Large4}>
                                    <img src={Wellness_Large4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>                             
                            </div>
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </SRLWrapper>
            </Container>
        </div>
    );
}
const TagButton = ({ name, handleSetTag, tagActive }) => {
	return (
		<button className={`tag ${tagActive ? 'active' : null}`} onClick={() => handleSetTag(name)}>
			{name.toUpperCase()}
		</button>
	);
};

export default Bathroom;