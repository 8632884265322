import {useEffect} from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

import { Container, Row, Col,} from 'reactstrap';
//Animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";



import dhruvasamal from '../images/dhruvasamal.jpg';

import pravirsethi from '../images/pravirsethi.jpg';
import ravivazirani from '../images/ravivazirani.jpg';
 import prakashjadhav from '../images/prakashjadhav.jpg';
import shiteshagarwal from '../images/shiteshagarwal.jpeg';
const Testimonials = () => {
    useEffect(()=>{
        Aos.init({duration: 2000, disable: 'mobile', delay: 2});
    });

    return(
        <div className="lyt-section">
            <Container>
            <div className="bs-heading sm-center">
                <div className="title typ-cps typ-testimonial" data-aos="slide-up" data-aos-once="false">Testimonials</div>
            </div>
            
            <div className="">
                <ResponsiveMasonry columnsCountBreakPoints={{0:1, 350: 1, 500:2,767: 2, 992: 3, 1024: 4}} className=
                "bs-masonry testimonial">
                    <Masonry gutter="2px">
                        <div data-aos="fade-up"  className="testimonial-card typ1">
                            <blockquote>
                                <p className="bs-para typ-normal">
                                As designers, it is rare for us to have a client who not only sees 
                                the value of great design but shows keen interest in the intimate details 
                                of the design and construction, whilst being respectful enough to give us 
                                breathing room to get these right. Working with TBS as a client was as easy 
                                as working with them as a vendor -  in both cases one is assured of dealing 
                                with thorough professionals.
                                </p>
                                <div className="cite">
                                    
                                    <img src={pravirsethi} alt="eclipse1" />
                                    <div>
                                        <p className="bs-para list">Pravir&nbsp;Sethi</p>
                                        <span>Studio Hinge Architects</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                        
                        <div data-aos="fade-up" className="testimonial-card typ2">
                            <blockquote>
                                <p className="bs-para typ-normal">
                                The Bath Studio has been my primary supplier of sanitary fittings ever 
                                since the inception of my practice. Their before and after sales customer 
                                service is impeccable as far as responsiveness and punctuality is concerned 
                                and these qualities go a long way in my books.
                                </p>
                                <div className="cite">
                                    
                                    <img src={dhruvasamal} alt="eclipse" />
                                    <div>
                                        <p className="bs-para list">Dhruva&nbsp;Samal</p>
                                        <span>Archtiect Mumbai</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                        
                        <div data-aos="fade-up" className="testimonial-card typ3">
                            <blockquote>
                                <p className="bs-para typ-normal">
                                I have been working with TBS for several years now for window solutions for most of my projects. I am happy working with them, as they work well according to my brief and have complete clarity, transparency and commitment throughout the process. They have in-depth product knowledge and work as consultants rather than sales people.

                                </p>
                                <div className="cite">
                                    
                                    <img src={ravivazirani} alt="eclipse" />
                                    <div>
                                        <p className="bs-para list">Ravi Vazirani</p>
                                        <span>Ravi Vazirani Design Studio</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>

                        <div data-aos="fade-up" className="testimonial-card typ4">
                            <blockquote>
                                <p className="bs-para typ-normal">
                                TBS has been associated with us for several years now regarding window solutions for our various projects. They provide workable solutions with complete transparency and flexibility well according to my brief. They consistently demonstrate a wealth of extensive knowledge and product quality updates through their wonderful display center.
                                </p>
                                <div className="cite">
                                    
                                    <img src={prakashjadhav} alt="eclipse" />
                                    <div>
                                        <p className="bs-para list">Prakash Jhadav</p>
                                        <span>Prakash Jhadav and Associates</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>

                        <div data-aos="fade-up" className="testimonial-card typ5">
                            <blockquote>
                                <p className="bs-para typ-normal">
                                I have known the team at TBS for many years and developed a wonderful working relationship with them. It’s always a pleasure to work with people who understand you and know so you can deliver a project efficiently.
                                </p>
                                <div className="cite">
                                    
                                    <img src={shiteshagarwal} alt="eclipse" />
                                    <div>
                                        <p className="bs-para list">Shitesh Agarwal</p>
                                        <span>Sankalp Designers</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </Masonry>
                </ResponsiveMasonry>
                
            </div>
            </Container>
        </div>
    )
}

export default Testimonials;