import {NavLink } from "react-router-dom";


import { Container,Containerfluid, Row, Col } from 'reactstrap';

import { useHistory } from "react-router-dom";

//Images
import backArrow from "../images/backArrow.png";
import brandImage12 from "../images/Brands/brandImage12.png";
import zanskar41 from "../images/Brands/zanskar41.png";


import brandDownload from "../images/Brands/brandDownload.png";

import Smithcatelogue1 from "../pdf/a.o.smith-1.pdf";
import Smithcatelogue2 from "../pdf/a.o.smith-2.pdf";

const Zanskar = (props) =>{
    let history = useHistory();
    return(
            <div className="">
               <Container fluid className="brand-detl">
        <div className="bs-brand-detail">
                <Row className="brand-img-detl">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 12 }} sm={12}  >
                        <div class="bs-arrow back" onClick={() => {history.goBack()}}>                  
                            <i className="icon icon-Line"></i>                        
                    </div>
                        <div className="content">
                        <img src={brandImage12} alt="Brand Image 1" className="zanskar_image"/>
                        <div className="bs-heading" style={{marginTop:'20px'}}>
                            <h2 className="title typ-cps black">about the brand</h2>    
                        </div>
                        <p className="bs-para typ-normal para1">Zanskar is a group company founded in 2020 with a focus on energy efficient water heating solutions - a space in which the group has built significant expertise over the last 10 years. Today, it's powered by the belief that air, energy and water should be made accessible to everyone in its purest form and on a sustainable basis. At present, the brand is rapidly expanding operations and now has a presence in over 7 cities across the country.</p>

                        {/* <p className="bs-para typ-normal para2">
                        The company’s entry into India was formalised with the incorporation of A.O Smith India, in 2006 with manufacturing facilities set up in Harohalli, Bengaluru - while catering to a market that covers water heating, heat pumps, storage water heaters and drinking water filtration.
                        </p> */}

                        <div className="bs-heading">
                            <h2 className="title typ-cps black">About the association </h2>
                        </div>
                        <p className="bs-para typ-normal para3">
                        TBS has been associated with Zanskar via Aquagreen, since its inception. The association focusses on a range of products and solutions which are meant to deliver the highest performance and customer experience.                        </p>
                        
                        {/* <div className="bs-heading">
                            <h2 className="title typ-cps black catalouge-title">catalogue</h2>
                        </div>
                        <div className="catalogue-desc bs-para">
                        <a href={Smithcatelogue1} download="Smithcatelogue1" target='_blank'>
                            <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)'}}>
                                A.O.Smith Master Catalogue Water Heater</p>
                              
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div>
                       
                        <div className="catalogue-desc bs-para">
                           
                                <a href={Smithcatelogue2} download="Smithcatelogue2" target='_blank'>
                                <p className="bs-para list" style={{borderBottom: '2px solid rgba(0, 0, 0, 0.1)', height: '250px'}}>
                            A.O.Smith&nbsp;Master&nbsp;Brochure&nbsp;Heat&nbsp;Pump</p>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                        {/* <div className="catalogue-desc bs-para">
                            <p className="bs-para list">
                                Kohler&nbsp;Luxury&nbsp;Brochure</p>
                                <a href={brandDownload} download="MyExampleDoc" target='_blank'>
                                    <i className="dwnload icon-download"></i>
                                </a>
                        </div> */}
                    </div>
                    </Col>  

                <Col lg={6} md={12} sm={12}>
                    <div className="image">
                    <img src={zanskar41} alt="Artist Edition - Kohler1" />
                    </div>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Zanskar;