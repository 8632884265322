import { NavLink } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useEffect } from "react";

import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-scroll";
import { SRLWrapper } from "simple-react-lightbox";

import { homePageUrl } from '../module/Constants.js';

//Animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";

//Images
import backArrow from "../images/backArrow.png";

import Alluminium_Window1 from "../images/Products/Alluminium_Window1.jpg";
import Alluminium_Window2 from "../images/Products/Alluminium_Window2.jpg";
import Alluminium_Window3 from "../images/Products/Alluminium_Window3.jpg";
import Alluminium_Window4 from "../images/Products/Alluminium_Window4.jpg";

import UPVC_Window1 from "../images/Products/UPVC_Window1.jpg";
import UPVC_Window2 from "../images/Products/UPVC_Window2.jpg";
import UPVC_Window3 from "../images/Products/UPVC_Window3.jpg";
import UPVC_Window4 from "../images/Products/UPVC_Window4.jpg";

import Wood_Window1 from "../images/Products/Wood_Window1.jpg";
import Wood_Window2 from "../images/Products/Wood_Window2.jpg";
import Wood_Window3 from "../images/Products/Wood_Window3.jpg";
import Wood_Window4 from "../images/Products/Wood_Window4.png";
import Wood_Window5 from "../images/Products/Wood_Window5.jpg";
import Wood_Window6 from "../images/Products/Wood_Window6.jpg";
import Wood_Window7 from "../images/Products/Wood_Window7.jpg";
import Wood_Window8 from "../images/Products/Wood_Window8.jpg";
import Wood_Window9 from "../images/Products/Wood_Window9.jpg";
import Wood_Window10 from "../images/Products/Wood_Window10.jpg";
import Wood_Window11 from "../images/Products/Wood_Window11.jpeg";
import Wood_Window12 from "../images/Products/Wood_Window12.jpg";

// large

import Alluminium_Window_Large1 from "../images/Products/large/Alluminium_Window1.jpg";
import Alluminium_Window_Large2 from "../images/Products/large/Alluminium_Window2.jpg";
import Alluminium_Window_Large3 from "../images/Products/large/Alluminium_Window3.jpg";
import Alluminium_Window_Large4 from "../images/Products/large/Alluminium_Window4.jpg";

import UPVC_Window_Large1 from "../images/Products/large/UPVC_Window1.jpg";
import UPVC_Window_Large2 from "../images/Products/large/UPVC_Window2.jpg";
import UPVC_Window_Large3 from "../images/Products/large/UPVC_Window3.jpg";
import UPVC_Window_Large4 from "../images/Products/large/UPVC_Window4.jpg";

import Wood_Window_Large1 from "../images/Products/large/Wood_Window1.jpg";
import Wood_Window_Large2 from "../images/Products/large/Wood_Window2.jpg";
import Wood_Window_Large3 from "../images/Products/large/Wood_Window3.jpg";
import Wood_Window_Large4 from "../images/Products/large/Wood_Window4.png";
import Wood_Window_Large5 from "../images/Products/large/Wood_Window5.jpg";
import Wood_Window_Large6 from "../images/Products/large/Wood_Window6.jpg";
import Wood_Window_Large7 from "../images/Products/large/Wood_Window7.jpg";
import Wood_Window_Large8 from "../images/Products/large/Wood_Window8.jpg";
import Wood_Window_Large9 from "../images/Products/large/Wood_Window9.jpg";
import Wood_Window_Large10 from "../images/Products/large/Wood_Window10.jpg";
import Wood_Window_Large11 from "../images/Products/large/Wood_Window11.jpeg";
import Wood_Window_Large12 from "../images/Products/large/Wood_Window12.jpg";

const Windows = () => {
    useEffect(() => {
        Aos.init({ duration: 2000, delay: 2 });
    });
    return (
        <div className="lyt-section">
            
            <Container>
                
                <div className="bs-hero">
                <Row>
                    <Col>
                        <div className="bs-arrow back">
                            {/* <NavLink exact to="/clients/tbs-website/products"><i className="icon icon-Line"></i></NavLink> */}
                            <NavLink exact to={`${homePageUrl}products`}><i className="icon icon-Line"></i></NavLink>
                        </div>
                    </Col>
                </Row>
                    <Row>
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1 }} sm={12} >
                    
                <div className="heading">
                <span className="title">Windows<sup>20</sup></span>
                </div>
                 </Col>
                <Col lg={6} md={6} sm={12}>
                <div className="description">
                    <div>
                    <h3 className="desc-title">View everything differently.</h3>
                    </div>
                    <div>
                        <p>High on style. Low on maintenance. Our window designs use the highest grade materials and come in a range of finishes that include aluminium, wood, and UPVC. Each, offering inimitable beauty, unmistakeable quality and the kind of sleek workmanship that can satisfy even the most discerning design brief.

                        </p>
                    </div>
                    <div>
                        <div class="feature-details">
                            <span className="specification">Featured Brands:&nbsp;</span>
                            <span className="spec-detail2">
                                <NavLink exact to={`${homePageUrl}fenesta`} className="detail-link">Fenesta</NavLink> | <NavLink exact to={`${homePageUrl}ritikaawood`} className="detail-link">Ritikaawood</NavLink>
                                {/* <NavLink exact to="/clients/tbs-website/fenesta" className="detail-link">Fenesta</NavLink> | <NavLink exact to="/clients/tbs-website/ritikaawood" className="detail-link">Ritikaawood</NavLink> */}

                            </span>
                        </div>
                    </div>
                    <div className="feature-details">
                        <div className="desc">
                            <span className="specification">Materials:&nbsp; </span>
                            <span className="spec-detail1">
                                <div className="bs-scroll-links">
                                    <Link activeClass="active" to="Alluminium"
                                        spy={true} smooth={true} offset={-100} duration={1000}>
                                        Aluminium |&nbsp;
                                    </Link>
                                    <Link activeClass="active" to="UPVC"
                                        spy={true} smooth={true} offset={-100} duration={1000}>
                                        UPVC |&nbsp;
                                    </Link>
                                    <Link activeClass="active" to="Wood"
                                        spy={true} smooth={true} offset={-100} duration={1000}>
                                        Wood
                                    </Link>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                </Col>
                    </Row>
            </div>
            <SRLWrapper>
                <div className="bs-masonry" >
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 992: 3 }} >
                        <Masonry gutter="20px">
                        <div data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
                                <a href={Alluminium_Window_Large1}>
                                    <img src={Alluminium_Window1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                        <div data-aos="fade-up" data-aos-once="false" data-aos-delay="300">
                                <a href={Alluminium_Window_Large2}>
                                    <img src={Alluminium_Window2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="400">
                                <a href={Alluminium_Window_Large3}>
                                    <img src={Alluminium_Window3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="500">
                                <a href={Alluminium_Window_Large4}>
                                    <img src={Alluminium_Window4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>

                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="600">
                                <a href={UPVC_Window_Large1}>
                                    <img src={UPVC_Window1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="700">
                                <a href={UPVC_Window_Large2}>
                                    <img src={UPVC_Window2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="800">
                                <a href={UPVC_Window_Large3}>
                                    <img src={UPVC_Window3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1000">
                                <a href={UPVC_Window_Large4}>
                                    <img src={UPVC_Window4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                        
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1100">
                                <a href={Wood_Window_Large1}>
                                    <img src={Wood_Window1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1200">
                                <a href={Wood_Window_Large2}>
                                    <img src={Wood_Window2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1300">
                            <a href={Wood_Window_Large3}>
                                    <img src={Wood_Window3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1400">
                            <a href={Wood_Window_Large4}>
                                    <img src={Wood_Window4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1500">
                            <a href={Wood_Window_Large5}>
                                    <img src={Wood_Window5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1600">
                            <a href={Wood_Window_Large6}>
                                    <img src={Wood_Window6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1700">
                            <a href={Wood_Window_Large7}>
                                    <img src={Wood_Window7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1800">
                            <a href={Wood_Window_Large8}>
                                    <img src={Wood_Window8} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="1900">
                            <a href={Wood_Window_Large9}>
                                    <img src={Wood_Window9} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2000">
                            <a href={Wood_Window_Large11}>
                                    <img src={Wood_Window11} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2100">
                            <a href={Wood_Window_Large10}>
                                    <img src={Wood_Window10} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            
                            <div data-aos="fade-up" data-aos-once="false" data-aos-delay="2200">
                            <a href={Wood_Window_Large12}>
                                    <img src={Wood_Window12} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                        
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </SRLWrapper>
            </Container>
           
        </div>
    );
}

export default Windows;