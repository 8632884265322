import { NavLink } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { SRLWrapper } from "simple-react-lightbox";

//Images
import backArrow from "../images/backArrow.png";
import windowImage1 from "../images/Projects/project-4_img-1.jpg";
import windowImage2 from "../images/Projects/project-4_img-2.jpg";
import windowImage3 from "../images/Projects/project-4_img-3.jpg";
import windowImage4 from "../images/Projects/project-4_img-4.jpg";
import windowImage5 from "../images/Projects/project-4_img-5.jpg";
import windowImage6 from "../images/Projects/project-4_img-6.jpg";
import windowImage7 from "../images/Projects/project-4_img-7.jpg";
import windowImage8 from "../images/Projects/project-4_img-8.jpg";

// large

import windowImage_Large1 from "../images/Projects/large/project-4_img-1.jpg";
import windowImage_Large2 from "../images/Projects/large/project-4_img-2.jpg";
import windowImage_Large3 from "../images/Projects/large/project-4_img-3.jpg";
import windowImage_Large4 from "../images/Projects/large/project-4_img-4.jpg";
import windowImage_Large5 from "../images/Projects/large/project-4_img-5.jpg";
import windowImage_Large6 from "../images/Projects/large/project-4_img-6.jpg";
import windowImage_Large7 from "../images/Projects/large/project-4_img-7.jpg";
import windowImage_Large8 from "../images/Projects/large/project-4_img-8.jpg";

const Projectdetail2 = () => {
    return (
        <div className="lyt-section">
            <div class="bs-arrow back">
                <a href="/projects"><i class="icon icon-Line"></i></a>
                {/* <NavLink exact to="/clients/tbs-website/projects"><i class="icon icon-Line"></i></NavLink> */}
            </div>
            <div className="bs-hero">
            <div className="heading typ-detail-heading">
                    <span className="title">Opolis Project </span>                    
                    <span className="title d-block">Ritikaa&nbsp;Wood</span>
                </div>
                <div className="description">
                    {/* <div>
                        <p className="black">An address that embodies the global standards in luxury, Lodha World towers
                            rises majestically above the city’s skyline featuring the city’s most stunning
                            apartments. One of those happen to highlight a collaboration between TBS and
                            nationally renowned architect Rahul Sharma - as seen here.
                        </p>
                    </div> */}
                    <div className="bs-project feature">
                        <div className="bs-heading">
                            <p className="title typ-cps black">architect</p>
                            <p className="title typ-cps black">Location </p>
                            <p className="title typ-cps black">Classification </p>
                            <p className="title typ-cps black">Featured&nbsp;brands</p>
                            <p className="title typ-cps black">Product&nbsp;showcase</p>
                        </div>
                        <div className="bs-heading">
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Rahul&nbsp;Sharma</p>
                            </div>
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Upper&nbsp;Worli</p>
                            </div>
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Residential</p>
                            </div>
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Fenesta/Ritikaawood</p>
                            </div>
                            <div className="bs-flex desc">
                                <span>:</span>
                                <p className="title typ-sml black">Windows, Doors, Sanitaryware, Surfaces</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SRLWrapper>
                <div className="bs-masonry">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 767: 2, 992: 3 }} >
                        <Masonry gutter="20px">
                            <div>
                                <a href={windowImage_Large1}>
                                    <img src={windowImage1} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div>
                                <a href={windowImage_Large2}>
                                    <img src={windowImage2} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div>
                                <a href={windowImage_Large3}>
                                    <img src={windowImage3} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div>
                                <a href={windowImage_Large4}>
                                    <img src={windowImage4} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Hinged casement wooden windows ideal for ventilation.</p> */}
                            </div>
                            <div>
                            <a href={windowImage_Large5}>
                                    <img src={windowImage5} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>UPVC sliding windows lending a contemporary touch.</p> */}
                            </div>
                            <div>
                            <a href={windowImage_Large6}>
                                    <img src={windowImage6} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div>
                            <a href={windowImage_Large7}>
                                    <img src={windowImage7} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                            <div>
                            <a href={windowImage_Large8}>
                                    <img src={windowImage8} alt="Artist Edition Bathroom 1" srl_gallery_image="true" />
                                </a>
                                {/* <p>Double hung aluminium windows with a classic appearance.</p> */}
                            </div>
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </SRLWrapper>
        </div>
    )
}

export default Projectdetail2;