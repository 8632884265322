import {NavLink } from "react-router-dom";


import { Container,Containerfluid, Row, Col } from 'reactstrap';


import { homePageUrl } from "../module/Constants";
const Sitemap = (props) =>{
   
    return(
            <div className="cm mtop-20">
               <Container >
        <div className="sitemap">
            <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="bs-heading" data-aos="slide-up" data-aos-once="false" data-aos-delay="50">
                <h2 className="title typ-sml typ-cps">Sitemap</h2>
              </div>
            </Col>
            </Row>
                <Row className="">
                    <Col lg={{ size: 5, offset: 1 }} md={{ size: 5, offset: 1}} sm={12}>                       
                    <ul className="ml-3">
                        <li><NavLink exact to={`${homePageUrl}`}>Home</NavLink></li>
                        <li><NavLink exact to={`${homePageUrl}about`}>About Us</NavLink></li>
                        <li><NavLink exact to={`${homePageUrl}products`}>products</NavLink>
                        <ul class="sub-menu">
                                <li><NavLink exact to={`${homePageUrl}bathroom`}>Bathroom</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}windows`} >Windows</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}doors`} >Doors</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}facades`} >Facades</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}railing`} >Railings and decking</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}surfaces`} >Surfaces</NavLink></li>  
                                <li><NavLink exact to={`${homePageUrl}watersolutions`} >Water Solutions</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink exact to={`${homePageUrl}brands`}>Brands</NavLink>
                        <ul class="sub-menu">
                                <li><NavLink exact to={`${homePageUrl}kolher`}>Kolher</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}artist_edition`} >Artist Editions</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}hangsrohe`} >Hangsrohe</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}axor`} >Axor</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}fenesta`} >Fenesta</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}ritikaawood`} >Ritikaawood</NavLink></li>  
                                <li><NavLink exact to={`${homePageUrl}vaillant`} >Vaillant</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}ebara`} >Ebara</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}smith`} >AO Smith</NavLink></li>
                                <li><NavLink exact to={`${homePageUrl}waterfileration`} >3M Home Water Solutions</NavLink></li>  
                                <li><NavLink exact to={`${homePageUrl}design_quarry`} >Design quarry</NavLink></li>                                
                            </ul>                        
                        </li>
                       
                        {/* <li>
                            <NavLink href="case-studies.php">Case Studies</NavLink>
                            <ul class="sub-menu">
                                <li><NavLink href="tata.php">Tata Communication</NavLink></li>
                                <li><NavLink href="happy-green.php">Happy Green</NavLink></li>
                                <li><NavLink href="hylasto.php">Hylasto</NavLink></li>
                                <li><NavLink href="impex.php">Impex</NavLink></li>
                                <li><NavLink href="bhadipa.php">Bhadipa</NavLink></li>
                                <li><NavLink href="bhimjyani.php">Bhimjyani</NavLink></li>
                            </ul>
                        </li> */}
                        
                        </ul>
                    </Col>  

                    <Col lg={{ size: 5 }} md={{ size: 5}} sm={12}>     
                    <ul>
                    <li><NavLink exact to={`${homePageUrl}projects`}>Projects</NavLink></li>
                        <li><NavLink exact to={`${homePageUrl}contact`}>Contact</NavLink></li>
                    <li><NavLink exact to={`${homePageUrl}experience`}>Experience</NavLink>
                       
                        </li>
                    </ul>
                    </Col>
                </Row>
                </div>
                </Container>
            </div>  
    )
} 

export default Sitemap;