import React, { useState, useRef, useEffect } from "react";
import { TweenLite, Power3 } from "gsap";



import nextArrow from "../images/nextArrow.png";
import HomeSlide1 from "../images/home-slide-1.png";
import HomeSlide2 from "../images/home-slide-2.png";

import "./trial.scss";


// import Swiper from 'react-id-swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller} from 'swiper';
import 'swiper/swiper-bundle.css';

import "../styles/custom.scss";

SwiperCore.use([Controller]);


// const slider = [
//     {
//       heading: "Live with inspiration.1",
//       title: "Holistic solutions for home and office spaces.",
//       image: `${HomeSlide1}`,
//       pagination: "01/03"
//     },
//     {
//         heading: "Live with inspiration.2",
//         title: "Holistic solutions for home and office spaces.",
//         image: `${HomeSlide2}`,
//         pagination: "02/03"
//     },
//     {
//         heading: "Live with inspiration.3",
//         title: "Holistic solutions for home and office spaces.",
//         image: `${HomeSlide1}`,
//         pagination: "03/03"
//     }
//   ];



const Trial= () =>{

    const ref = useRef(null);
  const [spaceBetween, setSpaceBetween] = useState(30);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  };

//     let imageList = useRef(null);
//     let textList = useRef(null);
//     let paginationList = useRef(null);
//     let countList = useRef(null);

//     const imageWidth = "100%";
//     const textHeight = 85;
//     const countHeight = 200;
//     //slider state
//     const [state, setState] = useState({
//         isActive1: true,
//         isActive2: false,
//         isActive3: false
//     });

//     useEffect(() => {
//         TweenLite.from(paginationList.children[0], 0, {
//           opacity: 1
//         });
//     }, []);

//     //Image transition
//     const slideLeft = (index, duration, multiplied = 1) => {
//         TweenLite.to(imageList.children[index], duration, {
//         x: 0,   
//         ease: Power3.easeOut
//         });
//     };
//     const slideRight = (index, duration, multiplied = 1) => {
//         TweenLite.to(imageList.children[index], duration, {
//           x: "-100",
//           ease: Power3.easeOut
//         });
//       };

//    // Text transition
//     const slideOutText = (index, duration, multiplied = 1) => {
//             TweenLite.to(textList.children[index].children, duration, {
//             y: 0,
//             opacity:1,
//             ease: Power3.easeOut
//         });
//     };
//     const slideInText = (index, duration, multiplied = 1) => {
//             TweenLite.to(textList.children[index].children, duration, {
//             y: 80,
//             opacity:0,
//             ease: Power3.easeOut
//         });
//     };

//     //Count transition
//     const slideOutCount = (index, duration, multiplied = 1) => {
//             TweenLite.to(countList.children[index], duration, {
//             y: -countHeight,
//             ease: Power3.easeOut
//         });
//     };
//     const slideInCount = (index, duration, multiplied = 1) => {
//             TweenLite.to(countList.children[index], duration, {
//             y: 0,
//             ease: Power3.easeOut
//         });
//     };

//     //Next slide

//     const nextSlide = () => {
//         if (imageList.children[0].classList.contains("active")) {
//           setState({ isActive1: false, isActive2: true });
//           //Image transition
//           slideLeft(0, 1);
//         //   slideLeft(1, 1);
//         //   slideLeft(2, 1);
//         //   slideLeft(2, 0);
//           // Text transition
//           slideOutText(2, 1);
//           slideInText(0, 1);
//           //Count
//           slideOutCount(2, 1);
//           slideInCount(0, 1);
//         } else if (imageList.children[1].classList.contains("active")) {
//           setState({ isActive2: false, isActive3: true });
//           //Image transition
//           slideRight(0, 1);
//           slideLeft(1, 1, 2);
//           slideLeft(2, 1, 2);
//           // Text transition
//           slideOutText(0, 1);
//           slideInText(1, 1);
//           slideOutCount(0, 1);
//           slideInCount(1, 1);

//         } else if (imageList.children[2].classList.contains("active")) {
//           setState({ isActive1: true, isActive3: false });
//           //Image transition
//           slideLeft(2, 1, 3);
//           slideLeft(0, 1, 0);
//           slideLeft(1, 0, 0);
//           // Text and Count transition
//           slideOutText(1, 1);
//           slideInText(2, 1);
//           slideOutCount(1, 1);
//           slideInCount(2, 1);
//         }
//       };


    return(
        <main className="bs-slider section">
            {/* <div className="slider-container">
                <div className="arrow-right" onClick={nextSlide}>
                    <img src={nextArrow} alt=" Next" />
                </div>
                <div className="s-pagination" >
                    <ul ref={el => (paginationList = el)}>
                        <li className={state.isActive1 ? "active" : ""}>
                            <div>{slider[0].pagination}</div>
                        </li>
                        <li className={state.isActive2 ? "active" : ""}>
                            <div>{slider[1].pagination}</div>
                        </li>
                        <li className={state.isActive3 ? "active" : ""}>
                            <div>{slider[2].pagination}</div>
                        </li>
                    </ul>
                </div>
                <div className="s-text">
                    <ul ref={el => (textList = el)}>
                        <li className={state.isActive1 ? "active" : ""}>
                            <div className="text-inner">
                                <h1 className="s-heading">{slider[0].heading}</h1>
                                <p className="s-title">{slider[0].title}</p>
                            </div>
                        </li>
                        <li className={state.isActive2 ? "active" : ""}>
                            <div className="text-inner">
                                <h1 className="s-heading">{slider[1].heading}</h1>
                                <p className="s-title">{slider[1].title}</p>
                            </div>
                        </li>
                        <li className={state.isActive3 ? "active" : ""}>
                            <div className="text-inner">
                                <h1 className="s-heading">{slider[2].heading}</h1>
                                <p className="s-title">{slider[2].title}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="s-inner">
                    <div className="s-image">
                        <ul ref={el => (imageList = el)}>
                            <li className={state.isActive1 ? "active" : ""}>
                               <img src={slider[0].image} alt={slider[0].heading + "Image"} />
                            </li>
                            <li className={state.isActive2 ? "active" : ""}>
                               <img src={slider[1].image} alt={slider[1].heading + "Image"} />
                            </li>
                            <li className={state.isActive3 ? "active" : ""}>
                               <img src={slider[2].image} alt={slider[2].heading + "Image"} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="s-count">
                    <ul ref={el => (countList = el)}>
                        <li className={state.isActive1 ? "active" : ""}>
                            <span>1</span>
                        </li>
                        <li className={state.isActive2 ? "active" : ""}>
                            <span>2</span>
                        </li>
                        <li className={state.isActive3 ? "active" : ""}>
                            <span>3</span>
                        </li>
                    </ul>
                </div>
                <ul className="s-zero">
                    <li>0</li>
                </ul>
             </div> */}
             <Swiper ref={ref} loop ={true} slidesPerView={1.5} spaceBetween={spaceBetween}
                loop={true} controller={{ control: controlledSwiper }}>
                <SwiperSlide>
                    One
                    <img src={HomeSlide1} alt="Home Image 1" />
                </SwiperSlide>
                <SwiperSlide>
                    Two
                    <img src={HomeSlide2} alt="Home Image 2" />
                </SwiperSlide>
                <SwiperSlide>
                    Three
                    <img src={HomeSlide1} alt="Home Image 3" />
                </SwiperSlide>
            </Swiper>
            <button className="button-next" onClick={goNext} style={{position:'absolute', width:"50px", height:"50px", zIndex:"5"}}>Next</button>
        </main>
    )
}

export default Trial;

